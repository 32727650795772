import { hideHeroElm } from '../../utils/main';
import { createErrorPageTemplate } from '../templates/template-creator';

/* eslint-disable no-console */
const ErrorPage = {
  async render() {
    hideHeroElm(true);
    return `
    <section id="breadcrumbs" class="breadcrumbs">
      <div class="container">
        <ol>
          <li><a href="#/home">Home</a></li>
          <li>Page not found</li>
        </ol>
      </div>
    </section>    <!-- ======= Error page Us Section ======= -->
    <section id="error-page" class="about"></section><!-- End Error page Section -->    `;
  },

  async afterRender() {
    // Fungsi ini akan dipanggil setelah render()
    document.querySelector('#error-page').innerHTML = createErrorPageTemplate(404);
  },
};
export default ErrorPage;

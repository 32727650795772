import UrlParser from '../../routes/url-parser';
import LppmDbSource from '../../data/lppmdb-source';
import {
  createWorkshopTemplate,
  createWorkshopSkeletonTemplate,
  createErrorPageTemplate,
  generatePagination,
} from '../templates/template-creator';
import { hideHeroElm } from '../../utils/main';

/* eslint-disable no-console */
const Workshop = {
  async render() {
    hideHeroElm(true);
    return `
    <section id="breadcrumbs" class="breadcrumbs">
      <div class="container">
        <ol>
          <li><a href="#/home">Home</a></li>
          <li>Layanan</li>
          <li>Pelatihan</li>
        </ol>
        <h2 id="workshop-title"></h2>
      </div>
    </section>
    <!-- ======= workshop page Us Section ======= -->
    <section id="our-workshop" class="services">
      <div class="container">
        <div id="workshop-container" class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-3 py-3">
          ${createWorkshopSkeletonTemplate(8)}         
        </div>
        <div class="row my-3">
          <div id="pager" class="d-flex justify-content-end">
          </div>
        </div>
      </div>
    </section><!-- workshop page Section -->    `;
  },

  async afterRender() {
    // Fungsi ini akan dipanggil setelah render()
    const url = UrlParser.parseActiveUrlWithoutCombiner();
    const p = (url.page === null ? 1 : url.page);
    const workshopList = await LppmDbSource.getWorkshop(url.id, p);
    const workshopContainer = document.querySelector('#workshop-container');
    const pagerContainer = document.querySelector('#pager');
    // eslint-disable-next-line max-len
    workshopContainer.innerHTML = (workshopList.status ? createWorkshopTemplate(workshopList.layanan) : createErrorPageTemplate(204));
    if (workshopList.status && workshopList.total_page > 1) {
      console.log(`${workshopList.page} ${workshopList.total_page}`);
      pagerContainer.innerHTML = generatePagination(parseInt(workshopList.page, 10), parseInt(workshopList.total_page, 10), `#/workshop/${url.id}`);
    }
  },
};
export default Workshop;

/* eslint-disable no-underscore-dangle */
import LppmDbSource from '../data/lppmdb-source';
import Swal from 'sweetalert2';
// import { createUserReviewsTemplate } from '../views/templates/template-creator';
// const $ = require('jquery');

const formRegInitiator = {
  async init({
    formId, ipAddrInput, eventSelectContainer, nameTextContainer, nidnTextContainer,
    instansiTextContainer, hpTextContainer, emailTextContainer, sendButtonContainer, wsId,
  }) {
    this._formId = formId;
    this._ipAddrInput = ipAddrInput;
    this._eventSelectContainer = eventSelectContainer;
    this._nameTextContainer = nameTextContainer;
    this._nidnTextContainer = nidnTextContainer;
    this._instansiTextContainer = instansiTextContainer;
    this._hpTextContainer = hpTextContainer;
    this._emailTextContainer = emailTextContainer;
    this._sendButtonContainer = sendButtonContainer;
    this._wsId = wsId;
    this._ws = null;

    await this._renderEventSelectContainer();
    await this._renderSendButton();
  },

  async _renderEventSelectContainer() {
    const myIp = await LppmDbSource.getIpAddress();
    if (myIp.ip) {
      this._ipAddrInput.value = myIp.ip;
    } else {
      this.ipAddrInput.value = '127.0.0.1';
    }
    const eventList = await LppmDbSource.getEventsOpen();
    if (eventList.status) {
      eventList.data.forEach((item) => {
        const option = document.createElement('option');
        option.text = item.kategori;
        option.value = item.id;
        this._eventSelectContainer.appendChild(option);
        this._eventSelectContainer.value = this._wsId;
      });
    } else {
      const option = document.createElement('option');
      option.text = 'Belum ada Pelatihan yang dibuka';
      option.value = '-1';
      this._eventSelectContainer.appendChild(option);
    }
  },

  async _renderSendButton() {
    // console.log(`name: ${this._nameTextContainer.value}`);
    this._sendButtonContainer.addEventListener('click', async (e) => {
      // tampilkan loading....
      if (this._eventSelectContainer.value <= 0) {
        this._eventSelectContainer.setAttribute('aria-invalid', true);
        this._eventSelectContainer.setCustomValidity('Silahkan pilih pelatihan');
      }

      if (this._formId.checkValidity()) {
        this._ws = {
          event_id: this._eventSelectContainer.value,
          name: this._nameTextContainer.value,
          nidn: this._nidnTextContainer.value,
          instansi: this._instansiTextContainer.value,
          hp: this._hpTextContainer.value,
          email: this._emailTextContainer.value,
          ip: this._ipAddrInput.value,
        };
        e.preventDefault();
        const response = await LppmDbSource.saveRegEvent(this._ws);
        const icon=response.status?'success':'error';
        const message=response.message;
        this._showAlert(icon,message);
        if(response.status) this._resetForm();
      }
    });
  },

  async _showAlert(icon,message){
    Swal.fire({
      position: 'top-end',
      icon: icon,
      title: message,
      showConfirmButton: true
    });
  },

  async _resetForm() {
    this._nameTextContainer.value = '';
    this._nidnTextContainer.value = '';
    this._instansiTextContainer.value = '';
    this._hpTextContainer.value = '';
    this._emailTextContainer.value = '';
  },
};

export default formRegInitiator;

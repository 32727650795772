import UrlParser from '../../routes/url-parser';
import LppmDbSource from '../../data/lppmdb-source';
import 'datatables.net-dt/css/jquery.dataTables.css';
import 'datatables.net-bs/css/dataTables.bootstrap.css';
import { hideHeroElm } from '../../utils/main';
import { createDtTableElement, createKegiatanTable } from '../templates/dttable-loader';
import { createKegiatanSkeletonTemplate, createRisetDetailTemplate, createRisetDetailSkeletonTemplate } from '../templates/template-creator';
// import bootstrapBundle from 'bootstrap/dist/js/bootstrap.bundle';

const $ = require('jquery');
$.DataTable = require('datatables.net');

/* eslint-disable no-console */
const Kegiatan = {
  async render() {
    hideHeroElm(true);
    return `
    <section id="breadcrumbs" class="breadcrumbs">
      <div class="container">
        <ol>
          <li><a href="#/home">Home</a></li>
          <li><span class="page-name-breadcrumbs">Penelitian Didanai</span></li>
        </ol>
        <h2 id="riset-title">Penelitian Didanai 5 Tahun Terakhir</h2>
      </div>
    </section>
    <!-- ======= riset page Us Section ======= -->
    <section id="our-riset" class="services">
      <div id="riset-container" class="container">
        ${createKegiatanSkeletonTemplate(10)}
      </div>
    </section><!-- riset page Section -->        
    <!-- Modal -->
    <div class="modal fade" id="detilModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Detil Kegiatan</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Tutup</button>
          </div>
        </div>
      </div>
    </div>`;
  },

  async afterRender() {
    // Fungsi ini akan dipanggil setelah render()
    const url = UrlParser.parseActiveUrlWithoutCombiner();
    const splitId = (url.resource ? url.resource.split('-') : []);
    const params = {
      jns: splitId[0].substring(0, 1) || 'r',
      // eslint-disable-next-line no-nested-ternary
      sd: (splitId[1] ? (splitId[1].toLowerCase() === 'eksternal' ? '2' : '1') : '1'),
    };
    let breadcrumbs = '';
    let pageTitle = '';
    const jnsKegiatan = (params.jns === 'r' ? 'Penelitian' : 'Abdimas');
    if (params.sd === '1') {
      breadcrumbs = `${jnsKegiatan} Didanai`;
      pageTitle = `${jnsKegiatan} Didanai 5 Tahun Terakhir`;
    } else {
      breadcrumbs = `${jnsKegiatan} Eksternal`;
      pageTitle = `${jnsKegiatan} Hibah Eksternal 5 Tahun Terakhir`;
    }
    document.querySelector('.page-name-breadcrumbs').innerHTML = breadcrumbs;
    document.querySelector('#riset-title').innerHTML = pageTitle;
    const dataSet = await LppmDbSource.getListPenelitian(params.jns, params.sd);
    document.querySelector('#riset-container').innerHTML = createDtTableElement('table');
    const tabel = createKegiatanTable(dataSet.data, params.jns, '#table');
    tabel.on('click', '.btn-detil', (event) => {
      // eslint-disable-next-line max-len
      // const myModal = new bootstrapBundle.Modal(document.getElementById('detilModal')); // creating modal object
      // myModal.show(); // show modal
      // myModal.hide(); // hide modal
      const dataFiltered = dataSet.data.filter((riset) => (riset.id === event.target.dataset.id));
      const elDetil = document.querySelector('#detilModal .modal-body');
      elDetil.innerHTML = createRisetDetailSkeletonTemplate();
      setTimeout(() => {
        elDetil.innerHTML = createRisetDetailTemplate(jnsKegiatan, dataFiltered[0]);
      }, 1000);
    });
  },
};
export default Kegiatan;

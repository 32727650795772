import UrlParser from '../../routes/url-parser';
import LppmDbSource from '../../data/lppmdb-source';
import {
  createReadContentTemplate,
  createReadContentSkeletonTemplate,
  createErrorPageTemplate,
} from '../templates/template-creator';
import { hideHeroElm } from '../../utils/main';

/* eslint-disable no-console */
const ReadService = {
  async render() {
    hideHeroElm(true);
    return `
    <section id="breadcrumbs" class="breadcrumbs">
      <div class="container">
        <ol id="page-breadcrumbs">
          <li><a href="#/home">Home</a></li>
          <li id="up-page"><a href="#" class="upper-page-breadcrumbs"></a></li>
          <li><span class="page-name-breadcrumbs"></span></li>
        </ol>
        <h2 id="services-title"></h2>
      </div>
    </section>
    <!-- ======= Services page Us Section ======= -->
    <section id="our-services" class="services">
      <div id="services-container" class="container">
        ${createReadContentSkeletonTemplate()}
      </div>
    </section><!-- Services page Section -->    `;
  },

  async afterRender() {
    // Fungsi ini akan dipanggil setelah render()
    const url = UrlParser.parseActiveUrlWithoutCombiner();
    const content = await LppmDbSource.readContent(url.id);
    const category = (url.page === null ? 0 : url.page);
    const servicesContainer = document.querySelector('#services-container');
    const priId = ['C05', 'C06', 'C07', 'C08'];
    const wsId = ['C09', 'C10', 'C11', 'C12'];
    const solarId = ['C13'];
    let breadcrumbs = '';
    let upperPageLink = '#';
    if (priId.includes(`C${category}`)) {
      breadcrumbs = 'Pusat Riset & Inovasi';
      upperPageLink = 'services';
    } else if (wsId.includes(`C${category}`)) {
      breadcrumbs = 'Pelatihan';
      upperPageLink = 'workshop';
    } else if (solarId.includes(`C${category}`)) {
      breadcrumbs = 'Solar Power Innovation Center';
      upperPageLink = 'solarpower';
    } else {
      breadcrumbs = 'Berita & Informasi';
      upperPageLink = '#/home';
    }
    const pageBreadcrumbs = document.querySelector('#page-breadcrumbs');
    if (breadcrumbs === 'Berita') { pageBreadcrumbs.removeChild(pageBreadcrumbs.children[1]); } else {
      document.querySelector('.upper-page-breadcrumbs').textContent = 'Layanan';
      document.querySelector('.upper-page-breadcrumbs').href = `#/${upperPageLink}/${category}`;
    }
    document.querySelector('.page-name-breadcrumbs').innerHTML = breadcrumbs;
    // eslint-disable-next-line max-len
    servicesContainer.innerHTML = (content.status ? createReadContentTemplate(content.data) : createErrorPageTemplate(204));
  },
};
export default ReadService;

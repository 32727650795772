/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable no-console */
import 'regenerator-runtime';
// Vendor CSS Files
import 'bootstrap/dist/css/bootstrap.css';
import 'aos/dist/aos.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'boxicons/css/boxicons.min.css';
import 'glightbox/dist/css/glightbox.min.css';
import 'remixicon/fonts/remixicon.css';
import '../vendor/swiper/swiper-bundle.min.css';
// main style
import '../styles/style.css';
// script
import AOS from 'aos';
import 'bootstrap/dist/js/bootstrap.bundle';
import GLightbox from 'glightbox';
import Swiper from 'swiper';
import Isotope from 'isotope-layout';
import Waypoint from 'waypoints/lib/noframework.waypoints';
import {
  select,
  on,
  onscroll,
  scrollto,
} from './utils/main';
import App from './views/app';

const app = new App({ navbar: select('#navbar .scrollto', true), content: select('#main', false) });
window.addEventListener('hashchange', () => {
  app.renderPage();
});

window.addEventListener('DOMContentLoaded', () => {
  app.renderPage();
});

// const navbarlinks = select('#navbar .scrollto', true);
//  onscroll(document, navbarlinksActive(navbarlinks));
/*
  console.log('set onload navbarlinksactive');
  window.addEventListener('DOMContentLoaded', navbarlinksActive(navbarlinks));
 */
/**
   * Toggle .header-scrolled class to #header when page is scrolled
   */
const selectHeader = select('#header');
if (selectHeader) {
  const headerScrolled = () => {
    if (window.scrollY > 100) {
      selectHeader.classList.add('header-scrolled');
    } else {
      selectHeader.classList.remove('header-scrolled');
    }
  };
  window.addEventListener('load', headerScrolled);
  onscroll(document, headerScrolled);
}

/**
   * Back to top button
   */
const backtotop = select('.back-to-top');
if (backtotop) {
  const toggleBacktotop = () => {
    if (window.scrollY > 100) {
      backtotop.classList.add('active');
    } else {
      backtotop.classList.remove('active');
    }
  };
  window.addEventListener('load', toggleBacktotop);
  onscroll(document, toggleBacktotop);
}

/**
   * Mobile nav toggle
   */
on('click', '.mobile-nav-toggle', function () {
  select('#navbar').classList.toggle('navbar-mobile');
  this.classList.toggle('bi-list');
  this.classList.toggle('bi-x');
});

/**
   * Mobile nav dropdowns activate
   */
on('click', '.navbar .dropdown > a', function (e) {
  if (select('#navbar').classList.contains('navbar-mobile')) {
    e.preventDefault();
    this.nextElementSibling.classList.toggle('dropdown-active');
  }
}, true);

/**
   * Scrool with ofset on links with a class name .scrollto
   */
on('click', '.scrollto', () => {
  // const elm = this.hash.replace('/', '');
  const navbar = select('#navbar');
  if (navbar.classList.contains('navbar-mobile')) {
    navbar.classList.remove('navbar-mobile');
    // console.log('toggle');
    const navbarToggle = select('.mobile-nav-toggle');
    navbarToggle.classList.toggle('bi-list');
    navbarToggle.classList.toggle('bi-x');
    // this.classList.add('active');
  }

/*
  if (select(elm)) {
    // console.log(`setelah switch ${elm}`);
    e.preventDefault();
    const navbar = select('#navbar');
    if (navbar.classList.contains('navbar-mobile')) {
      navbar.classList.remove('navbar-mobile');
      // console.log('toggle');
      const navbarToggle = select('.mobile-nav-toggle');
      navbarToggle.classList.toggle('bi-list');
      navbarToggle.classList.toggle('bi-x');
    }
    // scrollto('#main');
    // eslint-disable-next-line no-nested-ternary
    // console.log(elm);
  } */
}, true);

/**
   * Scroll with ofset on page load with hash links in the url
   */
window.addEventListener('load', () => {
  const location = window.location.hash.replace('/', '');
  if (location) {
    if (select(location)) {
      // console.log('skroll ke ...');
      scrollto(location);
    }
  }
});

/**
   * Preloader
   */
const preloader = select('#preloader');
if (preloader) {
  window.addEventListener('load', () => {
    preloader.remove();
  });
}

/**
   * Initiate  glightbox
   */
// eslint-disable-next-line no-unused-vars
const glightbox = GLightbox({
  selector: '.glightbox',
});

/**
   * Skills animation
   */
const skilsContent = select('.skills-content');
if (skilsContent) {
  // eslint-disable-next-line no-new
  new Waypoint({
    element: skilsContent,
    offset: '80%',
    // eslint-disable-next-line no-unused-vars
    handler(direction) {
      const progress = select('.progress .progress-bar', true);
      progress.forEach((el) => {
        el.style.width = `${el.getAttribute('aria-valuenow')}%`;
      });
    },
  });
}

/**
   * Porfolio isotope and filter
   */
window.addEventListener('load', () => {
  const portfolioContainer = select('.portfolio-container');
  if (portfolioContainer) {
    const portfolioIsotope = new Isotope(portfolioContainer, {
      itemSelector: '.portfolio-item',
    });

    const portfolioFilters = select('#portfolio-flters li', true);

    on('click', '#portfolio-flters li', function (e) {
      e.preventDefault();
      portfolioFilters.forEach((el) => {
        el.classList.remove('filter-active');
      });
      this.classList.add('filter-active');

      portfolioIsotope.arrange({
        filter: this.getAttribute('data-filter'),
      });
      portfolioIsotope.on('arrangeComplete', () => {
        AOS.refresh();
      });
    }, true);
  }
});

/**
   * Initiate portfolio lightbox
   */
// eslint-disable-next-line no-unused-vars
const portfolioLightbox = GLightbox({
  selector: '.portfolio-lightbox',
});

/**
   * Portfolio details slider
   */
// eslint-disable-next-line no-new
new Swiper('.portfolio-details-slider', {
  speed: 400,
  loop: true,
  autoplay: {
    delay: 5000,
    disableOnInteraction: false,
  },
  pagination: {
    el: '.swiper-pagination',
    type: 'bullets',
    clickable: true,
  },
});

/**
   * Animation on scroll
   */
window.addEventListener('load', () => {
  AOS.init({
    duration: 1000,
    easing: 'ease-in-out',
    once: true,
    mirror: false,
  });
});

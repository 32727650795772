import LppmDbSource from '../../data/lppmdb-source';
import { hideHeroElm } from '../../utils/main';
import { createErrorPageTemplate, createProfilSkeletonTemplate, createProfilTemplate } from '../templates/template-creator';

/* eslint-disable no-console */
const ProfilLppm = {
  async render() {
    hideHeroElm(true);
    return `
    <section id="breadcrumbs" class="breadcrumbs">
      <div class="container">
        <ol>
          <li><a href="#/home">Home</a></li>
          <li>Profil</li>
        </ol>
        <h2>Profil LPPM ITN Malang</h2>
      </div>
    </section>
    <!-- ======= About Us Section ======= -->
    <section id="about-us" class="about">
      <div id="about-container" class="container">
      ${createProfilSkeletonTemplate()}
      </div>
    </section><!-- End About Us Section -->    `;
  },

  async afterRender() {
    // Fungsi ini akan dipanggil setelah render()
    const data = await LppmDbSource.getProfil();
    const profilContainer = document.querySelector('#about-container');
    // eslint-disable-next-line max-len
    profilContainer.innerHTML = (data.status ? createProfilTemplate(data.profil) : createErrorPageTemplate(204));
  },
};
export default ProfilLppm;

import UrlParser from '../../routes/url-parser';
import LppmDbSource from '../../data/lppmdb-source';
import {
  createListDosenTemplate,
  createListDosenSkeletonTemplate,
  createErrorPageTemplate,
} from '../templates/template-creator';
import { hideHeroElm } from '../../utils/main';

/* eslint-disable no-console */
const ProfilDosen = {
  async render() {
    hideHeroElm(true);
    return `
    <section id="breadcrumbs" class="breadcrumbs">
      <div class="container">
        <ol>
          <li><a href="#/home">Home</a></li>
          <li><a href="#/profil-dosen">Dosen</a></li>
          <li><span class="page-name-breadcrumbs">Prodi</span></li>
        </ol>
        <h2 id="dosen-title">Data Dosen Institut Teknologi Nasional Malang</h2>
      </div>
    </section>
    <!-- ======= tim dosen page Section ======= -->
    <section id="team" class="team section-bg">
      <div class="container">
        <div id="dosen-container" class="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-2 g-3 py-3">
          ${createListDosenSkeletonTemplate(10)}         
        </div>
      </div>
    </section><!-- tim dosen page Section -->    `;
  },

  async afterRender() {
    // Fungsi ini akan dipanggil setelah render()
    const url = UrlParser.parseActiveUrlWithoutCombiner();
    const fakId = (url.page === null ? '01' : url.page);
    const dosenList = await LppmDbSource.getDosenProdi(url.id);
    const dosenContainer = document.querySelector('#dosen-container');
    const prodiName = dosenList.data.prodi.nama;
    document.querySelector('.page-name-breadcrumbs').innerHTML = prodiName;
    document.querySelector('#dosen-title').innerHTML = `Data Dosen ${prodiName}`;
    // eslint-disable-next-line max-len
    dosenContainer.innerHTML = (dosenList.status ? createListDosenTemplate(dosenList.data, fakId) : createErrorPageTemplate(204));
  },
};
export default ProfilDosen;

import Beranda from "../views/pages/beranda";
import ProfilLppm from "../views/pages/profil-lppm";
import Kegiatan from "../views/pages/kegiatan";
import PublikasiKegiatan from "../views/pages/kegiatan-pub";
import PublikasiJurnal from "../views/pages/publikasi-jurnal";
import PublikasiBuku from "../views/pages/publikasi-buku";
import PublikasiForum from "../views/pages/publikasi-forum";
import PublikasiHki from "../views/pages/publikasi-hki";
import PublikasiOthers from "../views/pages/publikasi-others";
import Dosen from "../views/pages/dosen";
import ProfilDosen from "../views/pages/profil-dosen";
import KegiatanDosen from "../views/pages/kegiatan-dosen";
import Services from "../views/pages/services";
import ReadService from "../views/pages/read-service";
import ErrorPage from "../views/pages/error-page";
import Workshop from "../views/pages/workshop";
import SolarPower from "../views/pages/solarpower";
import ResearchGrants from "../views/pages/research-grants";
import RegWorkshop from "../views/pages/reg-workshop";

const routes = {
  "/": Beranda, // default page
  "/home": Beranda,
  "/about": ProfilLppm,
  "/services/:id/:p": Services,
  "/view/:id/:p": ReadService,
  "/solarpower/:id/:p": SolarPower,
  "/research-grants/:id/:p": ResearchGrants,
  "/workshop/:id/:p": Workshop,
  "/riset": Kegiatan,
  "/riset-eksternal": Kegiatan,
  "/publikasi-riset": PublikasiKegiatan,
  "/publikasi-jurnal/:id/:p": PublikasiJurnal,
  "/publikasi-buku/:id/:p": PublikasiBuku,
  "/publikasi-forum/:id/:p": PublikasiForum,
  "/publikasi-hki/:id/:p": PublikasiHki,
  "/publikasi-lain/:id/:p": PublikasiOthers,
  "/profil-dosen": Dosen,
  "/profil-dosen/:id/:p": ProfilDosen,
  "/riset/:id/:p": KegiatanDosen,
  "/abdimas/:id/:p": KegiatanDosen,
  "/publikasi/:id/:p": KegiatanDosen,
  "/abdimas": Kegiatan,
  "/abdimas-eksternal": Kegiatan,
  "/publikasi-abdimas": PublikasiKegiatan,
  "/registrasi-pelatihan": RegWorkshop,
  "/registrasi-pelatihan/:id/:p": RegWorkshop,
  "/error-page": ErrorPage,
};

export default routes;

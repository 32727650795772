/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/**
   * Easy selector helper function
   */
const select = (el, all = false) => {
  const jSlash = el.search('/');
  el = (jSlash > 0 ? el.substring(0, jSlash) : el.trim());
  if (all) {
    return [...document.querySelectorAll(el)];
  }
  return document.querySelector(el);
};

/**
   * Easy event listener function
   */
const on = (type, el, listener, all = false) => {
  const selectEl = select(el, all);
  if (selectEl) {
    if (all) {
      selectEl.forEach((e) => e.addEventListener(type, listener));
    } else {
      selectEl.addEventListener(type, listener);
    }
  }
};

/**
   * Easy on scroll event listener
   */
const onscroll = (el, listener) => {
  el.addEventListener('scroll', listener);
};

/**
   * Navbar links active state on scroll
   */
const navbarlinksActive = (url, navbarlinks) => {
  // eslint-disable-next-line prefer-const
  let position = window.scrollY + 200;
  //  console.log(url);
  navbarlinks.forEach((navbarlink) => {
    if (!navbarlink.hash) return;
    const el = navbarlink.hash;
    url = (url === '/' ? '/home' : url);
    if (url === el.replace('#', '')) {
      navbarlink.classList.add('active');
    } else {
      navbarlink.classList.remove('active');
    }
  });
};

/**
   * Scrolls to an element with header offset
   */
const scrollto = (el) => {
//  console.log(el);
  const header = select('#header');
  const offset = header.offsetHeight;
  const elementPos = select(el).offsetTop;
  window.scrollTo({
    top: elementPos - offset,
    behavior: 'smooth',
  });
};

const decodeHtmlEntity = (str) => {
// str.replace(/&#(\d+);/g, (match, dec) => String.fromCharCode(dec));
  const element = document.createElement('div');
  if (str && typeof str === 'string') {
    // strip script/html tags
    str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gmi, '');
    str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '');
    element.innerHTML = str;
    str = element.textContent;
    element.textContent = '';
  }

  return str;
};

const hideHeroElm = (state) => {
  if (state === true) {
    document.querySelector('#header').classList.add('header-inner-pages');
    document.querySelector('#hero').classList.remove('d-flex');
    document.querySelector('#hero').classList.add('d-none');
  } else {
    document.querySelector('#header').classList.remove('header-inner-pages');
    document.querySelector('#hero').classList.remove('d-none');
    document.querySelector('#hero').classList.add('d-flex');
  }
};
export {
  select,
  on,
  onscroll,
  navbarlinksActive,
  scrollto,
  decodeHtmlEntity,
  hideHeroElm,
};

import UrlParser from '../../routes/url-parser';
import LppmDbSource from '../../data/lppmdb-source';
import 'datatables.net-dt/css/jquery.dataTables.css';
import 'datatables.net-bs/css/dataTables.bootstrap.css';
import { hideHeroElm } from '../../utils/main';
import { createJmlPublikasiSkeletonTemplate, createListJmlPublikasiTemplate } from '../templates/template-creator';

const $ = require('jquery');
$.DataTable = require('datatables.net');

/* eslint-disable no-console */
const PublikasiKegiatan = {
  async render() {
    hideHeroElm(true);
    return `
    <section id="breadcrumbs" class="breadcrumbs">
      <div class="container">
        <ol>
          <li><a href="#/home">Home</a></li>
          <li><span class="page-name-breadcrumbs">Publikasi Penelitian</span></li>
        </ol>
        <h2 id="riset-title">Daftar Publikasi Penelitian</h2>
      </div>
    </section>
    <!-- ======= riset page Us Section ======= -->
    <section id="our-riset" class="service">
      <div id="riset-container" class="container">
        ${createJmlPublikasiSkeletonTemplate()}
      </div>
    </section><!-- riset page Section -->        
    <!-- Modal -->
    <div class="modal fade" id="detilModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Detil Penelitian</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Tutup</button>
          </div>
        </div>
      </div>
    </div>`;
  },

  async afterRender() {
    // Fungsi ini akan dipanggil setelah render()
    const url = UrlParser.parseActiveUrlWithoutCombiner();
    const splitId = (url.resource ? url.resource.split('-') : []);
    const jns = splitId[1].substring(0, 1) || 'r';
    const jnsKegiatan = (jns === 'r' ? 'Penelitian' : 'Abdimas');
    document.querySelector('.page-name-breadcrumbs').innerHTML = `Publikasi ${jnsKegiatan}`;
    document.querySelector('#riset-title').innerHTML = `Publikasi ${jnsKegiatan} 5 Tahun Terakhir`;
    const dataPublikasi = await LppmDbSource.getJmlPublikasi(jns);
    document.querySelector('#riset-container').innerHTML = createListJmlPublikasiTemplate(jns, dataPublikasi.data);
  },
};
export default PublikasiKegiatan;

import UrlParser from '../../routes/url-parser';
import LppmDbSource from '../../data/lppmdb-source';
import 'datatables.net-dt/css/jquery.dataTables.css';
import 'datatables.net-bs/css/dataTables.bootstrap.css';
import { hideHeroElm } from '../../utils/main';
import { createDtTableElement, createForumTable } from '../templates/dttable-loader';
import { createKegiatanSkeletonTemplate } from '../templates/template-creator';

const $ = require('jquery');
$.DataTable = require('datatables.net');

/* eslint-disable no-console */
const PublikasiForum = {
  async render() {
    hideHeroElm(true);
    return `
    <section id="breadcrumbs" class="breadcrumbs">
      <div class="container">
        <ol>
          <li><a href="#/home">Home</a></li>
          <li><a href="#" class="upper-page-breadcrumbs">Publikasi</a></li>
          <li><span class="page-name-breadcrumbs">Forum Ilmiah</span></li>
        </ol>
        <h2 id="riset-title"></h2>
      </div>
    </section>
    <!-- ======= riset page Us Section ======= -->
    <section id="our-riset" class="service">
      <div id="riset-container" class="container">
        ${createKegiatanSkeletonTemplate(10)}
      </div>
    </section><!-- riset page Section -->`;
  },

  async afterRender() {
    // Fungsi ini akan dipanggil setelah render()
    const url = UrlParser.parseActiveUrlWithoutCombiner();
    const dataSet = await LppmDbSource.getListForumIlmiah(url.id);
    console.log(dataSet);
    const jnsKegiatan = (url.id === 'r' ? 'Penelitian' : 'Abdimas');
    const page = (url.id === 'r' ? 'riset' : 'abdimas');
    document.querySelector('.upper-page-breadcrumbs').textContent = `Publikasi ${jnsKegiatan}`;
    document.querySelector('.upper-page-breadcrumbs').href = `#/publikasi-${page}`;
    document.querySelector('.page-name-breadcrumbs').innerHTML = 'Forum Ilmiah';
    document.querySelector('#riset-title').innerHTML = `Forum Ilmiah ${jnsKegiatan} 5 Tahun Terakhir`;
    document.querySelector('#riset-container').innerHTML = createDtTableElement('table');
    createForumTable(dataSet.data, 'Forum', '#table');
  },
};
export default PublikasiForum;

import API_ENDPOINT from "../globals/api-endpoint";
import CONFIG from "../globals/config";

class LppmDbSource {
  static async getFrontPage() {
    const response = await fetch(API_ENDPOINT.FRONTPAGE, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "app-token": CONFIG.KEY,
      },
    }).catch((err) => ({ status: false, msg: err.statusText }));
    /* 
    }); */
    const responseJson = await response.json();
    return responseJson;
  }

  static async getProfil() {
    const response = await fetch(API_ENDPOINT.PROFIL, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "app-token": CONFIG.KEY,
      },
    }).catch((err) => ({ status: false, msg: err.statusText }));
    const responseJson = await response.json();
    return responseJson;
  }

  static async getServices(id, page) {
    const response = await fetch(API_ENDPOINT.PRI(id, page), {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "app-token": CONFIG.KEY,
      },
    }).catch((err) => ({ status: false, msg: err.statusText }));
    const responseJson = await response.json();
    return responseJson;
  }
  static async getResearchGrants(id, page) {
    const response = await fetch(API_ENDPOINT.RSG(id, page), {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "app-token": CONFIG.KEY,
      },
    }).catch((err) => ({ status: false, msg: err.statusText }));
    const responseJson = await response.json();
    return responseJson;
  }

  static async getWorkshop(id, page) {
    const response = await fetch(API_ENDPOINT.WS(id, page), {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "app-token": CONFIG.KEY,
      },
    }).catch((err) => ({ status: false, error: err }));
    const responseJson = await response.json();
    return responseJson;
  }

  static async readContent(id) {
    const response = await fetch(API_ENDPOINT.READ_CONTENT(id), {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "app-token": CONFIG.KEY,
      },
    }).catch((err) => ({ status: false, msg: err.statusText }));
    const responseJson = await response.json();
    return responseJson;
  }

  static async getListPenelitian(jns, sd) {
    const response = await fetch(API_ENDPOINT.RISET(jns, sd), {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "app-token": CONFIG.KEY,
      },
    }).catch((err) => ({ status: false, msg: err.statusText }));
    const responseJson = await response.json();
    return responseJson;
  }

  static async getListKegiatanDosen(jns, dosen) {
    const response = await fetch(API_ENDPOINT.KEGIATAN_DOSEN(jns, dosen), {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "app-token": CONFIG.KEY,
      },
    }).catch((err) => ({ status: false, msg: err.statusText }));
    const responseJson = await response.json();
    return responseJson;
  }

  static async getJmlPublikasi(jns) {
    const response = await fetch(API_ENDPOINT.JML_PUBLIKASI(jns), {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "app-token": CONFIG.KEY,
      },
    }).catch((err) => ({ status: false, msg: err.statusText }));
    const responseJson = await response.json();
    return responseJson;
  }

  static async getListJurnal(jns) {
    const response = await fetch(API_ENDPOINT.JURNAL(jns), {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "app-token": CONFIG.KEY,
      },
    }).catch((err) => ({ status: false, msg: err.statusText }));
    const responseJson = await response.json();
    return responseJson;
  }

  static async getListJurnalDosen(dosen) {
    const response = await fetch(API_ENDPOINT.JURNAL_DOSEN(dosen), {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "app-token": CONFIG.KEY,
      },
    }).catch((err) => ({ status: false, msg: err.statusText }));
    const responseJson = await response.json();
    return responseJson;
  }

  static async getListBukuAjar(jns) {
    const response = await fetch(API_ENDPOINT.BUKU_AJAR(jns), {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "app-token": CONFIG.KEY,
      },
    }).catch((err) => ({ status: false, msg: err.statusText }));
    const responseJson = await response.json();
    return responseJson;
  }

  static async getListBukuDosen(dosen) {
    const response = await fetch(API_ENDPOINT.BUKU_DOSEN(dosen), {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "app-token": CONFIG.KEY,
      },
    }).catch((err) => ({ status: false, msg: err.statusText }));
    const responseJson = await response.json();
    return responseJson;
  }

  static async getListForumIlmiah(jns) {
    const response = await fetch(API_ENDPOINT.FORUM_ILMIAH(jns), {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "app-token": CONFIG.KEY,
      },
    }).catch((err) => ({ status: false, msg: err.statusText }));
    const responseJson = await response.json();
    return responseJson;
  }

  static async getListForumIlmiahDosen(dosen) {
    const response = await fetch(API_ENDPOINT.FORUM_ILMIAH_DOSEN(dosen), {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "app-token": CONFIG.KEY,
      },
    }).catch((err) => ({ status: false, msg: err.statusText }));
    const responseJson = await response.json();
    return responseJson;
  }

  static async getListHki(jns) {
    const response = await fetch(API_ENDPOINT.HKI(jns), {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "app-token": CONFIG.KEY,
      },
    }).catch((err) => ({ status: false, msg: err.statusText }));
    const responseJson = await response.json();
    return responseJson;
  }

  static async getListHkiDosen(dosen) {
    const response = await fetch(API_ENDPOINT.HKI_DOSEN(dosen), {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "app-token": CONFIG.KEY,
      },
    }).catch((err) => ({ status: false, msg: err.statusText }));
    const responseJson = await response.json();
    return responseJson;
  }

  static async getListLuaranLain(jns) {
    const response = await fetch(API_ENDPOINT.LUARAN_LAIN(jns), {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "app-token": CONFIG.KEY,
      },
    }).catch((err) => ({ status: false, msg: err.statusText }));
    const responseJson = await response.json();
    return responseJson;
  }

  static async getListLuaranLainDosen(dosen) {
    const response = await fetch(API_ENDPOINT.LUARAN_LAIN_DOSEN(dosen), {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "app-token": CONFIG.KEY,
      },
    }).catch((err) => ({ status: false, msg: err.statusText }));
    const responseJson = await response.json();
    return responseJson;
  }

  static async getJmlDosenProdi() {
    const response = await fetch(API_ENDPOINT.JML_DOSEN_PRODI, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "app-token": CONFIG.KEY,
      },
    }).catch((err) => ({ status: false, msg: err.statusText }));
    const responseJson = await response.json();
    return responseJson;
  }

  static async getDosenProdi(prodi) {
    const response = await fetch(API_ENDPOINT.DOSEN_PRODI(prodi), {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "app-token": CONFIG.KEY,
      },
    }).catch((err) => ({ status: false, msg: err.statusText }));
    const responseJson = await response.json();
    return responseJson;
  }

  static async getProfilDosen(id) {
    const response = await fetch(API_ENDPOINT.PROFIL_DOSEN(id), {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "app-token": CONFIG.KEY,
      },
    }).catch((err) => ({ status: false, msg: err.statusText }));
    const responseJson = await response.json();
    return responseJson;
  }

  static async getScopusDosen(id) {
    const response = await fetch(API_ENDPOINT.SCOPUS_DOSEN(id)).catch(
      (err) => ({ status: false, msg: err.statusText })
    );
    const responseJson = await response.json();
    return responseJson;
  }

  static async getEventsOpen() {
    const response = await fetch(API_ENDPOINT.EVENTS_OPEN, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "app-token": CONFIG.KEY,
      },
    }).catch((err) => ({ status: false, msg: err.statusText }));
    const responseJson = await response.json();
    return responseJson;
  }

  static async getIpAddress() {
    const response = await fetch(API_ENDPOINT.IP_ADDR).catch((err) => ({
      status: false,
      msg: err.statusText,
    }));
    const responseJson = await response.json();
    return responseJson;
  }

  static async saveRegEvent(regData) {
    const formData = new FormData();
    Object.keys(regData).forEach((key) => formData.append(key, regData[key]));
    const response = await fetch(API_ENDPOINT.EVENTS_REG, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "app-token": CONFIG.KEY,
      },
      body: JSON.stringify(regData),
    }).catch((err) => ({ status: false, msg: err.statusText }));
    const responseJson = await response.json();
    return responseJson;
  }
}

export default LppmDbSource;

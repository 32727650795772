import UrlParser from "../../routes/url-parser";
import LppmDbSource from "../../data/lppmdb-source";
import {
  createServicesTemplate,
  createServiceSkeletonTemplate,
  generatePagination,
  createErrorPageTemplate,
} from "../templates/template-creator";
import { hideHeroElm } from "../../utils/main";

/* eslint-disable no-console */
const ResearchGrants = {
  async render() {
    hideHeroElm(true);
    return `
    <section id="breadcrumbs" class="breadcrumbs">
      <div class="container">
        <ol>
          <li><a href="#/home">Home</a></li>
          <li>Research Grants</li>
        </ol>
        <h2 id="services-title"></h2>
      </div>
    </section>
    <!-- ======= Services page Us Section ======= -->
    <section id="our-services" class="services">
      <div class="container">
        <div id="researchGrants-container" class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-3 py-3">
          ${createServiceSkeletonTemplate(8)}         
        </div>
        <div class="row my-3">
          <div id="pager" class="d-flex justify-content-end">
          </div>
        </div>
      </div>
    </section><!-- Services page Section -->    `;
  },

  async afterRender() {
    // Fungsi ini akan dipanggil setelah render()
    const url = UrlParser.parseActiveUrlWithoutCombiner();
    const p = url.page === null ? 1 : url.page;
    const researchGrantList = await LppmDbSource.getResearchGrants(url.id, p);
    // console.log(researchGrantList);
    const researchGrantsContainer = document.querySelector(
      "#researchGrants-container"
    );
    const pagerContainer = document.querySelector("#pager");
    // eslint-disable-next-line max-len
    researchGrantsContainer.innerHTML = researchGrantList.status
      ? createServicesTemplate(researchGrantList.layanan)
      : createErrorPageTemplate(204);
    if (researchGrantList.status && researchGrantList.total_page > 1) {
      // console.log(`${researchGrantList.page} ${researchGrantList.total_page}`);
      pagerContainer.innerHTML = generatePagination(
        parseInt(researchGrantList.page, 10),
        parseInt(researchGrantList.total_page, 10),
        `#/research-grants/${url.id}`
      );
    }
  },
};
export default ResearchGrants;

import UrlParser from '../../routes/url-parser';
// import LppmDbSource from '../../data/lppmdb-source';
import { createRegWorkshopTemplate } from '../templates/template-creator';
import { hideHeroElm } from '../../utils/main';
import formRegInitiator from '../../utils/form-registrasi-initiator';

/* eslint-disable no-console */
const RegWorkshop = {
  async render() {
    hideHeroElm(true);
    return `
    <section id="breadcrumbs" class="breadcrumbs">
      <div class="container">
        <ol>
          <li><a href="#/home">Home</a></li>
          <li>Registrasi</li>
        </ol>
        <h2 id="services-title">Registrasi Pelatihan</h2>
      </div>
    </section>
    <!-- ======= reg page Us Section ======= -->
    <section id="our-services" class="services">
      <div class="container">
        <div id="reg-form-container" class="row content">
        </div>
      </div>
    </section><!-- reg page Section -->    `;
  },

  async afterRender() {
    // Fungsi ini akan dipanggil setelah render()
    const url = UrlParser.parseActiveUrlWithoutCombiner();
    const id = (url.id === null ? 0 : url.id);
    const formContainer = document.querySelector('#reg-form-container');
    formContainer.innerHTML = createRegWorkshopTemplate;
    formRegInitiator.init({
      formId: document.querySelector('#form-reg'),
      ipAddrInput: document.querySelector('#ip'),
      eventSelectContainer: document.querySelector('#events'),
      nameTextContainer: document.querySelector('#name'),
      nidnTextContainer: document.querySelector('#nidn'),
      instansiTextContainer: document.querySelector('#institusi'),
      hpTextContainer: document.querySelector('#hp'),
      emailTextContainer: document.querySelector('#email'),
      sendButtonContainer: document.querySelector('#sendButton'),
      wsId: id,
    });
  },
};
export default RegWorkshop;

import UrlParser from '../../routes/url-parser';
import LppmDbSource from '../../data/lppmdb-source';
import {
  createServicesTemplate,
  generatePagination,
  createServiceSkeletonTemplate,
  createErrorPageTemplate,
} from '../templates/template-creator';
import { hideHeroElm } from '../../utils/main';

/* eslint-disable no-console */
const Services = {
  async render() {
    hideHeroElm(true);
    return `
    <section id="breadcrumbs" class="breadcrumbs">
      <div class="container">
        <ol>
          <li><a href="#/home">Home</a></li>
          <li>Layanan</li>
          <li>Pusat Riset & Inovasi</li>
        </ol>
        <h2 id="services-title"></h2>
      </div>
    </section>
    <!-- ======= Services page Us Section ======= -->
    <section id="our-services" class="services">
      <div class="container">
        <div id="services-container" class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-3 py-3">
          ${createServiceSkeletonTemplate(8)}         
        </div>
        <div class="row my-3">
          <div id="pager" class="d-flex justify-content-end">
          </div>
        </div>
      </div>
    </section><!-- Services page Section -->    `;
  },

  async afterRender() {
    // Fungsi ini akan dipanggil setelah render()
    const url = UrlParser.parseActiveUrlWithoutCombiner();
    const p = (url.page === null ? 1 : url.page);
    const serviceList = await LppmDbSource.getServices(url.id, p);
    // const container = document.querySelector('.container');
    const servicesContainer = document.querySelector('#services-container');
    /*   const servicesItem = document.querySelector('.service-item');
    for (let i = 0; i < 6 - 1; i += 1) {
      servicesContainer.append(servicesItem.cloneNode(true));
    }
 */
    // createServiceSkeletonTemplate(servicesContainer, servicesItem, 6);
    /*     servicesContainer.innerHTML = '';
    serviceList.layanan.forEach((serviceItem) => {
      const div = servicesItem.cloneNode(true);
      div.querySelector('.card-link').textContent = 'Selengkapnya';
      div.querySelector('.card-link').href = '#/services/01/read';
      div.querySelector('.card-img-top').src = 'assets/img/cta-bg.jpg';
      div.querySelector('.card-title').textContent = serviceItem.judul_konten;
      div.querySelector('.card-text').classList.remove('skeleton-box');
      div.querySelector('.card-text').innerHTML = serviceItem.intro_cut;
      servicesContainer.append(div);
    }); */
    const pagerContainer = document.querySelector('#pager');
    // eslint-disable-next-line max-len
    servicesContainer.innerHTML = (serviceList.status ? createServicesTemplate(serviceList.layanan) : createErrorPageTemplate(204));
    if (serviceList.status && serviceList.total_page > 1) {
      console.log(`${serviceList.page} ${serviceList.total_page}`);
      pagerContainer.innerHTML = generatePagination(parseInt(serviceList.page, 10), parseInt(serviceList.total_page, 10), `#/services/${url.id}`);
    }
  },
};
export default Services;

import CONFIG from '../../globals/config';

const $ = require('jquery');
$.DataTable = require('datatables.net');

const createDtTableElement = (id) => `
    <div class="table-responsive">
      <table id="${id}" class="table table-hover table-sm jambo_table" style="width:100%"></table>
    </div>
    `;

const getDtTableLanguage = (text) => ({
  search: `Cari ${text}:`,
  info: 'Menampilkan _START_ - _END_ dari _TOTAL_ data',
  infoEmpty: 'Belum ada data',
  lengthMenu: 'Tampilkan _MENU_ data',
  emptyTable: 'Tidak ada data yang bisa ditampilkan',
  paginate: {
    first: '<<',
    last: '>>',
    next: '>',
    previous: '<',
  },
});

const createKegiatanTable = (dataset, jns, tableElm) => {
  const jnsKegiatan = (jns === 'r' ? 'Penelitian' : 'Abdimas');
  return $(tableElm).dataTable({
    data: dataset,
    // ajaxSource: dataSet,
    paging: true,
    ordering: false,
    info: true,
    columns: [
      {
        data: 'tahun',
        title: `Judul ${jnsKegiatan}`,
        render(data, type, row) {
          return `<p>${row.judul}</p><div class="d-flex justify-content-start" title="Tahun & Skema Kegiatan"><span class="text-muted me-3"><i class="bx bx-calendar-event"></i> ${data}</span>-<span class="text-info ms-3"><i class="bx bx-cabinet"></i> ${row.skim}</span></div>`;
        },
      },
      {
        data: null,
        title: 'Sumber Dana',
        className: 'datamoney',
        render(data, type, row) {
          return `${row.sumber_dana}<br><span class="text-warning d-flex justify-content-start"><i class="ri-donut-chart-line"></i>&nbsp;${row.total_dana}</span>`;
        },
      },
      { data: 'lokasi', title: `Lokasi ${jnsKegiatan}` },
      {
        data: null,
        title: 'Opsi',
        className: 'buttoncell',
        render(data, type, row) {
          return `<button data-id="${row.id}" class="btn btn-primary btn-sm btn-detil" data-bs-toggle="modal" data-bs-target="#detilModal"><i class="bx bx-info-circle" data-id="${row.id}"></i></button>`;
        },
      },
    ],
    language: getDtTableLanguage(jnsKegiatan),
    sPaginationType: 'full_numbers',
  });
};

const createJurnalTable = (dataset, jns, tableElm) => $(tableElm).dataTable({
  data: dataset,
  paging: true,
  ordering: false,
  info: true,
  columns: [
    {
      data: null,
      title: 'Judul Jurnal',
      className: 'datatitle',
      render(data, type, row) {
        return `<p>${row.judul}</p><div class="d-flex justify-content-start" title="Tahun terbit & jenis publikasi"><span class="text-muted me-3"><i class="bx bx-calendar-event"></i> ${row.tahun}</span>-<span class="text-info ms-3"><i class="bx bx-book"></i> ${row.jenis_publikasi}</span></div>`;
      },
    },
    {
      data: null,
      title: 'Penulis',
      className: 'dataperson',
      render(data, type, row) {
        return `<p>${row.penulis.map((penulis) => penulis.nama).join('; ')}</p>`;
      },
    },
    {
      data: null,
      title: 'Identitas Jurnal',
      className: 'dataperson',
      render(data, type, row) {
        return `<b title="Nama jurnal"><i>${row.nama_jurnal}</i></b><br><span class="text-warning text-muted" title="ISSN"><i class="ri-barcode-line"></i>&nbsp;<b>${row.issn}</b></span><br><span class="text-muted" title="Edisi"><i class="ri-book-open-line"></i>&nbsp;<b>${row.volume}/${row.nomor}</b>, <b>${row.page_start}-${row.page_end}</b></span><br><div class="d-flex justify-content-start text-muted" title="Link jurnal"><i class="bx bx-link-alt"></i>&nbsp;<a href="${row.url}" target="_blank">${row.url}</a></div>`;
      },
    },
    {
      data: null,
      title: 'Berkas',
      className: 'buttoncell',
      render(data, type, row) {
        return `<a href="${CONFIG.BASE_REPORT_URL}${row.id.replace(/LJ/gi,'').substr(0,4)}/${row.berkas}" target="_blank" class="btn btn-primary btn-sm ${(row.berkas === '' ? ' disabled' : '')}"><i class="bx bx-download"></i></a>`;
      },
    }],
  language: getDtTableLanguage(jns),
  sPaginationType: 'full_numbers',
});

const createBukuTable = (dataset, jns, tableElm) => $(tableElm).dataTable({
  data: dataset,
  // ajaxSource: dataSet,
  paging: true,
  ordering: false,
  info: true,
  columns: [
    {
      data: null,
      title: 'Judul Buku',
      render(data, type, row) {
        return `<p>${row.judul}</p><div class="d-flex justify-content-start" title="Tahun terbit & jumlah halaman"><span class="text-muted me-3"><i class="bx bx-calendar-event"></i> ${row.tahun}</span>-<span class="text-info ms-3"><i class="ri-pages-line"></i>&nbsp;${row.jml_halaman} halaman</span></div>`;
      },
    },
    {
      data: 'isbn',
      title: 'ISBN',
    },
    {
      data: null,
      title: 'Identitas Buku',
      className: 'dataperson',
      render(data, type, row) {
        return `<p class="mb-1" title="Penulis Buku"><b>Penulis:</b> ${row.penulis.map((penulis) => penulis.nama).join('; ')}</p><span title="Penerbit" class="text-muted"><b>Penerbit:</b> ${row.penerbit}</span>`;
      },
    },
    {
      data: null,
      title: 'Berkas',
      className: 'buttoncell',
      render(data, type, row) {
        return `<a href="${CONFIG.BASE_REPORT_URL}${row.id.replace(/LB/gi,'').substr(0,4)}/${row.berkas}" target="_blank" class="btn btn-primary btn-sm ${(row.berkas === '' ? ' disabled' : '')}"><i class="bx bx-download"></i></a>`;
      },
    },
  ],
  language: getDtTableLanguage(jns),
  sPaginationType: 'full_numbers',
});

const createForumTable = (dataset, jns, tableElm) => $(tableElm).dataTable({
  data: dataset,
  paging: true,
  ordering: false,
  info: true,
  columns: [
    {
      data: null,
      title: 'Judul Makalah',
      render(data, type, row) {
        return `<p>${row.judul}</p><div class="d-flex justify-content-start" title="Tahun terbit & status Pemakalah"><span class="text-muted me-3"><i class="bx bx-calendar-event"></i> ${row.tahun}</span>-<span class="text-info ms-3"><i class="bx bx-user-check"></i>&nbsp;${row.status_pemakalah}</span></div>`;
      },
    },
    {
      data: null,
      title: 'Nama Forum',
      render(data, type, row) {
        return `Forum: <b>${row.nama_forum} - ${row.tingkat_forum}</b><br>Institusi: <b>${row.penyelenggara}</b>`;
      },
    },
    {
      data: null,
      title: 'Pelaksanaan',
      render(data, type, row) {
        return `Tgl: <b>${row.ts_start}</b><br>s/d: <b>${row.ts_end}</b><br>Tempat: <b>${row.lokasi}</b>`;
      },
    },
    {
      data: null,
      title: 'Berkas',
      className: 'buttoncell',
      render(data, type, row) {
        return `<a href="${CONFIG.BASE_REPORT_URL}${row.id.replace(/LF/gi,'').substr(0,4)}/${row.berkas}" target="_blank" class="btn btn-primary btn-sm ${(row.berkas === '' ? ' disabled' : '')}"><i class="bx bx-download"></i></a>`;
      },
    },
  ],
  language: getDtTableLanguage(jns),
  sPaginationType: 'full_numbers',
});

const createHkiTable = (dataset, jns, tableElm) => $(tableElm).dataTable({
  data: dataset,
  paging: true,
  ordering: false,
  info: true,
  columns: [
    {
      data: null,
      title: 'Judul HKI',
      render(data, type, row) {
        return `<p>${row.judul}</p><span class="text-muted" title="Tahun terbit"><i class="bx bx-calendar-event"></i>&nbsp;${data.tahun}</span>`;
      },
    },
    {
      data: null,
      title: 'Identitas HKI',
      render(data, type, row) {
        return `<b>${row.jns_hki} ${row.status_hki}</b><br>No. Reg: <b>${data.no_reg}</b><br>No. HKI: <b>${data.no_hki}</b>`;
      },
    },
    {
      data: null,
      title: 'Pencipta',
      className: 'dataperson',
      render(data, type, row) {
        return `<p>${row.pencipta.map((pencipta) => pencipta.nama).join('; ')}</p>`;
      },
    },
    {
      data: null,
      title: 'Berkas',
      className: 'buttoncell',
      render(data, type, row) {
        return `<a href="${CONFIG.BASE_REPORT_URL}${row.id.replace(/LH/gi,'').substr(0,4)}/${row.berkas}" target="_blank" class="btn btn-primary btn-sm ${(row.berkas === '' ? ' disabled' : '')}"><i class="bx bx-download"></i></a>`;
      },
    },
  ],
  language: getDtTableLanguage(jns),
  sPaginationType: 'full_numbers',
});

const createLuaranLainTable = (dataset, jns, tableElm) => $(tableElm).dataTable({
  data: dataset,
  paging: true,
  ordering: false,
  info: true,
  columns: [
    {
      data: null,
      title: 'Judul Luaran',
      render(data, type, row) {
        return `<p>${row.judul}</p><span title="Tahun publikasi"><span class="text-muted"><i class="bx bx-calendar-event"></i> ${row.tahun}</span></span>`;
      },
    },
    {
      data: 'jns_luaran',
      title: 'Jenis Luaran',
    },
    {
      data: null,
      title: 'Pencipta',
      className: 'dataperson',
      render(data, type, row) {
        return `<p>${row.pencipta.map((pencipta) => pencipta.nama).join('; ')}</p>`;
      },
    },
    {
      data: null,
      title: 'Berkas',
      className: 'buttoncell',
      render(data, type, row) {
        return `<a href="${CONFIG.BASE_REPORT_URL}${row.id.replace(/L/gi,'').substr(0,4)}/${row.berkas}" target="_blank" class="btn btn-primary btn-sm ${(row.berkas === '' ? ' disabled' : '')}"><i class="bx bx-download"></i></a>`;
      },
    },
  ],
  language: getDtTableLanguage(jns),
  sPaginationType: 'full_numbers',
});

export {
  createDtTableElement,
  createKegiatanTable,
  createJurnalTable,
  createBukuTable,
  createForumTable,
  createHkiTable,
  createLuaranLainTable,
};

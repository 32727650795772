import UrlParser from '../../routes/url-parser';
import LppmDbSource from '../../data/lppmdb-source';
import 'datatables.net-dt/css/jquery.dataTables.css';
import 'datatables.net-bs/css/dataTables.bootstrap.css';
import { hideHeroElm, scrollto } from '../../utils/main';
import {
  createDtTableElement, createKegiatanTable, createJurnalTable,
  createBukuTable, createForumTable, createHkiTable, createLuaranLainTable,
} from '../templates/dttable-loader';
import {
  writeProfilDosen,
  createKegiatanSkeletonTemplate,
  createRisetDetailTemplate,
  createRisetDetailSkeletonTemplate,
} from '../templates/template-creator';
import createChart from '../templates/chart-creator';

// const $ = require('jquery');
// $.DataTable = require('datatables.net');

/* eslint-disable no-console */
const KegiatanDosen = {
  async render() {
    hideHeroElm(true);
    return `
    <section id="breadcrumbs" class="breadcrumbs">
      <div class="container">
        <ol>
          <li><a href="#/home">Home</a></li>
          <li><a href="#" class="upper-page-breadcrumbs">Daftar Dosen</a></li>
          <li><span class="page-name-breadcrumbs">Profil</span></li>
        </ol>
        <h2 id="profil-title">Profil & Riwayat Kegiatan Dosen</h2>
      </div>
    </section>
    <!-- ======= profil page Us Section ======= -->
    <section id="our-riset" class="service">
      <div class="container">
        <div class="col-12">
          <div class="row">
            <div class="col-lg-3 col-md-4 col-sm-12">
              <div class="card border-info mb-3">
                <div class="card-header bg-info">Profil Dosen</div>
                <div class="card-body">
                  <div class="w-100 text-center mb-1">
                    <img class="dosen-profile-img img-responsive img-circle skeleton-img" alt="">
                  </div>

                  <p class="dosen-name-text text-center skeleton-box">nama lengkap dosen</p>

                </div>
                <div class="list-group list-group-flush m-2" id="list-tab" role="tablist">
                  <a class="list-group-item list-group-item-action d-flex justify-content-between align-items-center active" id="list-riset-list" data-bs-toggle="list" href="#list-riset" role="tab" aria-controls="list-riset">Penelitian<span class="badge bg-dark badge-pill badge-riset">0</span></a>
                  <a class="list-group-item list-group-item-action d-flex justify-content-between align-items-center" id="list-abdimas-list" data-bs-toggle="list" href="#list-abdimas" role="tab" aria-controls="list-abdimas">Abdimas<span class="badge bg-dark badge-pill badge-abdimas">0</span></a>
                  <a class="list-group-item list-group-item-action d-flex justify-content-between align-items-center" id="list-jurnal-list" data-bs-toggle="list" href="#list-jurnal" role="tab" aria-controls="list-jurnal">Jurnal<span class="badge bg-dark badge-pill badge-jurnal">0</span></a>
                  <a class="list-group-item list-group-item-action d-flex justify-content-between align-items-center" id="list-buku-list" data-bs-toggle="list" href="#list-buku" role="tab" aria-controls="list-buku">Buku Ajar<span class="badge bg-dark badge-pill badge-buku">0</span></a>
                  <a class="list-group-item list-group-item-action d-flex justify-content-between align-items-center" id="list-forum-list" data-bs-toggle="list" href="#list-forum" role="tab" aria-controls="list-forum">Forum Ilmiah<span class="badge bg-dark badge-pill badge-forum">0</span></a>
                  <a class="list-group-item list-group-item-action d-flex justify-content-between align-items-center" id="list-hki-list" data-bs-toggle="list" href="#list-hki" role="tab" aria-controls="list-hki">HKI<span class="badge bg-dark badge-pill badge-hki">0</span></a>
                  <a class="list-group-item list-group-item-action d-flex justify-content-between align-items-center" id="list-luaran-lain-list" data-bs-toggle="list" href="#list-luaran-lain" role="tab" aria-controls="list-luaran-lain">Luaran-Lain<span class="badge bg-dark badge-pill badge-lain">0</span></a>
                </div>
              </div>
              <div class="card border-info">
                <div class="card-header">About me</div>
                <div class="card-body">
                  <strong><i class="bx bxs-institution mr-3"></i> Instansi &amp; Jabatan</strong>

                  <p class="dosen-instansi-text skeleton-box">Prodi dan jabatan<br></p>

                  <hr>

                  <strong><i class="ri-mail-line"></i> Email</strong>

                  <p class="dosen-mail-text skeleton-box">mymail@lecturer.itn.ac.id</p>

                  <hr>

                  <strong><i class="ri-dashboard-3-line mr-3"></i> H-Index</strong>

                  <p class="dosen-kp-text sc skeleton-box mb-1 d-flex justify-content-between align-items-center">Scopus         :</p>
                  <p class="dosen-kp-text gs skeleton-box mb-1 d-flex justify-content-between align-items-center">Google Scholar :</p>
                </div>
              </div>
            </div>
            <div class="col-lg-9 col-md-8 col-sm-12">
              <div class="tab-content" id="nav-tabContent">
                <div class="tab-pane fade show active" id="list-riset" role="tabpanel" aria-labelledby="list-riset-list">
                  <div class="card">
                    <div class="card-header">Riwayat Penelitian</div>
                    <div id="riset-chart-box" class="chart-box mb-3 skeleton-box">
                      <div id="riset-chart-title" class="text-center small"></div>
                      <canvas id="riset-chart"></canvas>
                    </div>
                    <div class="card-body">
                        <div id="riset-container">
                          ${createKegiatanSkeletonTemplate(10)}
                        </div>                          
                    </div>
                  </div>
                </div>
                <div class="tab-pane fade" id="list-abdimas" role="tabpanel" aria-labelledby="list-abdimas-list">
                  <div class="card">
                    <div class="card-header">Riwayat Pengabdian kpd Masyarakat</div>
                    <div id="abdimas-chart-box" class="chart-box mb-3 skeleton-box">
                      <div id="abdimas-chart-title" class="text-center small"></div>
                      <canvas id="abdimas-chart"></canvas>
                    </div>
                    <div class="card-body">
                      <div id="abdimas-container">
                          ${createKegiatanSkeletonTemplate(10)}
                        </div>                          
                    </div>
                  </div>
                </div>
                <div class="tab-pane fade" id="list-jurnal" role="tabpanel" aria-labelledby="list-jurnal-list">
                  <div class="card">
                    <div class="card-header">Riwayat Publikasi Jurnal</div>
                    <div id="jurnal-chart-box" class="chart-box mb-3 skeleton-box">
                      <div id="jurnal-chart-title" class="text-center small"></div>
                      <canvas id="jurnal-chart"></canvas>
                    </div>
                    <div class="card-body">
                      <div id="jurnal-container">
                          ${createKegiatanSkeletonTemplate(10)}
                        </div>                          
                    </div>
                  </div>                
                </div>
                <div class="tab-pane fade" id="list-buku" role="tabpanel" aria-labelledby="list-buku-list">
                  <div class="card">
                    <div class="card-header">Riwayat Publikasi Buku Ajar / Teks</div>
                    <div id="buku-chart-box" class="chart-box mb-3 skeleton-box">
                      <div id="buku-chart-title" class="text-center small"></div>
                      <canvas id="buku-chart"></canvas>
                    </div>
                    <div class="card-body">
                      <div id="buku-container">
                          ${createKegiatanSkeletonTemplate(10)}
                        </div>                          
                    </div>
                  </div>                
                </div>
                <div class="tab-pane fade" id="list-forum" role="tabpanel" aria-labelledby="list-forum-list">
                  <div class="card">
                    <div class="card-header">Riwayat Publikasi Forum Ilmiah</div>
                    <div id="forum-chart-box" class="chart-box mb-3 skeleton-box">
                      <div id="forum-chart-title" class="text-center small"></div>
                      <canvas id="forum-chart"></canvas>
                    </div>
                    <div class="card-body">
                      <div id="forum-container">
                          ${createKegiatanSkeletonTemplate(10)}
                        </div>                          
                    </div>
                  </div>                
                </div>
                <div class="tab-pane fade" id="list-hki" role="tabpanel" aria-labelledby="list-hki-list">
                  <div class="card">
                    <div class="card-header">Riwayat Publikasi Hak Kekayaan Intelektual</div>
                    <div id="hki-chart-box" class="chart-box mb-3 skeleton-box">
                      <div id="hki-chart-title" class="text-center small"></div>
                      <canvas id="hki-chart"></canvas>
                    </div>
                    <div class="card-body">
                      <div id="hki-container">
                          ${createKegiatanSkeletonTemplate(10)}
                        </div>                          
                    </div>
                  </div>                
                </div>
                <div class="tab-pane fade" id="list-luaran-lain" role="tabpanel" aria-labelledby="list-luaran-lain-list">
                  <div class="card">
                    <div class="card-header">Riwayat Publikasi Lainnya</div>
                    <div id="luaran-lain-chart-box" class="chart-box mb-3 skeleton-box">
                      <div id="luaran-lain-chart-title" class="text-center small"></div>
                      <canvas id="luaran-lain-chart"></canvas>
                    </div>
                    <div class="card-body">
                      <div id="luaran-lain-container">
                          ${createKegiatanSkeletonTemplate(10)}
                        </div>                          
                    </div>
                  </div>                
                </div>
              </div>            
            </div>
          </div>
        </div>      
      </div>
    </section><!-- riset page Section -->        
    <!-- Modal -->
    <div class="modal fade" id="detilModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Detil Kegiatan</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Tutup</button>
          </div>
        </div>
      </div>
    </div>`;
  },

  async afterRender() {
    /*     Fungsi ini akan dipanggil setelah render()
    ambil data dosen, penelitian, abdimas, dan semua publikasi
    awalnya data null dulu, kemudian jika tab diklik
    cek jika = null maka load, jika tidak maka tampilkan */

    // const scopusDosen = await LppmDbSource.getScopusDosen('7004212771');// hanya contoh
    // console.log(scopusDosen);
    /*     const gs = await fetch('http://scholar.google.se/citations?user=ZMO8VjwAAAAJ') // id user contoh punya pak awan
      .then((response) => response.text())
      .catch((err) => console.log(err));
    const regex = 'h-index</a></td><td class="gsc_rsb_std">';
    const pos = gs.search(regex); // return text position h-index text
    // ambil 4 karakter mulai angka h-index dari posisi regex
    const hIndexNum = gs.substr(pos + regex.length, 4);
    const gsHIndex = hIndexNum.substr(0, hIndexNum.search('</'));
    console.log(gsHIndex);
 */

    const url = UrlParser.parseActiveUrlWithoutCombiner();
    const jns = url.resource.substring(0, 1) || 'r';
    const profil = await LppmDbSource.getProfilDosen(url.id);
    writeProfilDosen(profil);// , gsHIndex, 0);
    const jnsKegiatan = (jns === 'r' ? 'Penelitian' : 'Abdimas');
    const prodiId = profil.data.prodi.id;
    const fakId = (url.page === null ? '01' : url.page);
    document.querySelector('.upper-page-breadcrumbs').href = `#/profil-dosen/${prodiId}/${fakId}`;
    let tRiset = null;
    let tAbdimas = null;
    let tJurnal = null;
    let tBuku = null;
    let tForum = null;
    let tHki = null;
    let tLain = null;
    let dataRiset = null;
    let dataAbdimas = null;
    let dataJurnal = null;
    let dataBuku = null;
    let dataForum = null;
    let dataHki = null;
    let dataLain = null;
    const tabItemId = (jns === 'p' ? 'jurnal' : url.resource);
    document.querySelectorAll('.list-group-item').forEach((el) => {
      if (el.id === `list-${tabItemId}-list`) {
        el.classList.add('active');
      } else {
        el.classList.remove('active');
      }
    });
    document.querySelectorAll('.tab-pane').forEach((el) => {
      if (el.id === `list-${tabItemId}`) {
        el.classList.add('show', 'active');
      } else {
        el.classList.remove('show', 'active');
      }
    });
    const risetContainer = document.querySelector('#riset-container');
    const abdimasContainer = document.querySelector('#abdimas-container');
    const jurnalContainer = document.querySelector('#jurnal-container');
    const bukuContainer = document.querySelector('#buku-container');
    const forumContainer = document.querySelector('#forum-container');
    const hkiContainer = document.querySelector('#hki-container');
    const lainContainer = document.querySelector('#luaran-lain-container');
    if (jns === 'r') {
      dataRiset = await LppmDbSource.getListKegiatanDosen(jns, url.id);
      risetContainer.innerHTML = createDtTableElement('triset');
      if (tRiset === null) {
        tRiset = createKegiatanTable(dataRiset.data, jns, '#triset');
        tRiset.on('click', '.btn-detil', (event) => {
          // eslint-disable-next-line max-len
          const dataFiltered = dataRiset.data.filter((riset) => (riset.id === event.target.dataset.id));
          const elDetil = document.querySelector('#detilModal .modal-body');
          elDetil.innerHTML = createRisetDetailSkeletonTemplate();
          setTimeout(() => {
            elDetil.innerHTML = createRisetDetailTemplate(jnsKegiatan, dataFiltered[0]);
          }, 1000);
        });
      }
      // tampilkan grafik
      const { statistik } = dataRiset;
      createChart(statistik, '#riset-chart', 'Penelitian', 0);
    } else if (jns === 'a') {
      dataAbdimas = await LppmDbSource.getListKegiatanDosen(jns, url.id);
      abdimasContainer.innerHTML = createDtTableElement('tabdimas');
      if (tAbdimas === null) {
        tAbdimas = createKegiatanTable(dataAbdimas.data, jns, '#tabdimas');
        tAbdimas.on('click', '.btn-detil', (event) => {
          // eslint-disable-next-line max-len
          const dataFiltered = dataAbdimas.data.filter((riset) => (riset.id === event.target.dataset.id));
          const elDetil = document.querySelector('#detilModal .modal-body');
          elDetil.innerHTML = createRisetDetailSkeletonTemplate();
          setTimeout(() => {
            elDetil.innerHTML = createRisetDetailTemplate(jnsKegiatan, dataFiltered[0]);
          }, 1000);
        });
      }
      const { statistik } = dataAbdimas;
      createChart(statistik, '#abdimas-chart', 'Abdimas', 1);
    } else if (jns === 'p') {
      dataJurnal = await LppmDbSource.getListJurnalDosen(url.id);
      jurnalContainer.innerHTML = createDtTableElement('tjurnal');
      if (tJurnal === null) {
        tJurnal = createJurnalTable(dataJurnal.data, 'Jurnal', '#tjurnal');
      }
      // tampilkan grafik
      const { statistik } = dataJurnal;
      createChart(statistik, '#jurnal-chart', 'Jurnal', 2);
    }
    document.querySelector('#list-riset-list').addEventListener('click', async () => {
      if (tRiset === null) {
        dataRiset = await LppmDbSource.getListKegiatanDosen('r', url.id);
        risetContainer.innerHTML = createDtTableElement('triset');
        tRiset = createKegiatanTable(dataRiset.data, 'r', '#triset');
        tRiset.on('click', '.btn-detil', (event) => {
          // eslint-disable-next-line max-len
          const dataFiltered = dataRiset.data.filter((riset) => (riset.id === event.target.dataset.id));
          const elDetil = document.querySelector('#detilModal .modal-body');
          elDetil.innerHTML = createRisetDetailSkeletonTemplate();
          setTimeout(() => {
            elDetil.innerHTML = createRisetDetailTemplate('Penelitian', dataFiltered[0]);
          }, 1000);
        });
        // tampilkan grafik
        const { statistik } = dataRiset;
        createChart(statistik, '#riset-chart', 'Penelitian', 0);
      }
      scrollto('#our-riset');
    });
    document.querySelector('#list-abdimas-list').addEventListener('click', async () => {
      if (tAbdimas === null) {
        dataAbdimas = await LppmDbSource.getListKegiatanDosen('a', url.id);
        abdimasContainer.innerHTML = createDtTableElement('tabdimas');
        tAbdimas = createKegiatanTable(dataAbdimas.data, 'a', '#tabdimas');
        tAbdimas.on('click', '.btn-detil', (event) => {
          // eslint-disable-next-line max-len
          const dataFiltered = dataAbdimas.data.filter((riset) => (riset.id === event.target.dataset.id));
          const elDetil = document.querySelector('#detilModal .modal-body');
          elDetil.innerHTML = createRisetDetailSkeletonTemplate();
          setTimeout(() => {
            elDetil.innerHTML = createRisetDetailTemplate('Abdimas', dataFiltered[0]);
          }, 1000);
        });
        // tampilkan grafik
        const { statistik } = dataAbdimas;
        createChart(statistik, '#abdimas-chart', 'Abdimas', 1);
      }
      scrollto('#our-riset');
    });
    document.querySelector('#list-jurnal-list').addEventListener('click', async () => {
      if (tJurnal === null) {
        dataJurnal = await LppmDbSource.getListJurnalDosen(url.id);
        jurnalContainer.innerHTML = createDtTableElement('tjurnal');
        tJurnal = createJurnalTable(dataJurnal.data, 'Jurnal', '#tjurnal');
        // tampilkan grafik
        const { statistik } = dataJurnal;
        createChart(statistik, '#jurnal-chart', 'Jurnal', 2);
      }
      scrollto('#our-riset');
    });
    document.querySelector('#list-buku-list').addEventListener('click', async () => {
      if (tBuku === null) {
        dataBuku = await LppmDbSource.getListBukuDosen(url.id);
        bukuContainer.innerHTML = createDtTableElement('tbuku');
        tBuku = createBukuTable(dataBuku.data, 'Buku', '#tbuku');
        // tampilkan grafik
        const { statistik } = dataBuku;
        createChart(statistik, '#buku-chart', 'Buku Ajar / Teks', 3);
      }
      scrollto('#our-riset');
    });
    document.querySelector('#list-forum-list').addEventListener('click', async () => {
      if (tForum === null) {
        dataForum = await LppmDbSource.getListForumIlmiahDosen(url.id);
        forumContainer.innerHTML = createDtTableElement('tforum');
        tForum = createForumTable(dataForum.data, 'Forum', '#tforum');
        // tampilkan grafik
        const { statistik } = dataForum;
        createChart(statistik, '#forum-chart', 'Forum Ilmiah', 4);
      }
      scrollto('#our-riset');
    });
    document.querySelector('#list-hki-list').addEventListener('click', async () => {
      if (tHki === null) {
        dataHki = await LppmDbSource.getListHkiDosen(url.id);
        hkiContainer.innerHTML = createDtTableElement('thki');
        tHki = createHkiTable(dataHki.data, 'HKI', '#thki');
        // tampilkan grafik
        const { statistik } = dataHki;
        createChart(statistik, '#hki-chart', 'HKI', 5);
      }
      scrollto('#our-riset');
    });
    document.querySelector('#list-luaran-lain-list').addEventListener('click', async () => {
      if (tLain === null) {
        dataLain = await LppmDbSource.getListLuaranLainDosen(url.id);
        lainContainer.innerHTML = createDtTableElement('tlain');
        tLain = createLuaranLainTable(dataLain.data, 'Luaran Lain', '#tlain');
        // tampilkan grafik
        const { statistik } = dataLain;
        createChart(statistik, '#luaran-lain-chart', 'Luaran Lain', 6);
      }
      scrollto('#our-riset');
    });
  },
};
export default KegiatanDosen;

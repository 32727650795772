/* eslint-disable no-console */
/* eslint-disable no-underscore-dangle */

// eslint-disable-next-line import/no-unresolved
import UrlParser from '../routes/url-parser';
import routes from '../routes/routes';
import { navbarlinksActive, scrollto } from '../utils/main';

class App {
  constructor({ navbar, content }) {
    this._navbar = navbar;
    this._content = content;
    this._initialAppShell();
  }

  _initialAppShell() {
    const myLink = this._navbar; // .getElementsByClassName('nav-link');
    // console.log(myLink);
    // Loop through the buttons and add the active class to the current/clicked button
    // eslint-disable-next-line no-plusplus
    myLink.forEach((element) => {
      // eslint-disable-next-line func-names
      element.addEventListener('click', function () {
        const current = document.querySelectorAll('.active');
        if (current[0]) { current[0].className = current[0].className.replace(' active', ''); }
        this.className += ' active';
      });
    });
  }

  async renderPage() {
    let url = UrlParser.parseActiveUrlWithoutCombiner();
    url = (url.id !== null && url.page === null ? `${UrlParser.parseActiveUrlWithCombiner()}/:p` : UrlParser.parseActiveUrlWithCombiner());
    let page = routes[url];
    // console.log(url);
    if (page === undefined) {
      //      console.log('halaman tidak ditemukan');
      url = '/error-page';
      page = routes[url];
    }
    this._content.innerHTML = await page.render();
    await page.afterRender();
    window.addEventListener('load', navbarlinksActive(url, this._navbar));
    if (url === '/' || url === '/home') {
      // eslint-disable-next-line no-nested-ternary
      scrollto((url === '/' ? '#hero' : (url === '/home' ? '#hero' : url.replace('/', '#'))));
    } else {
      scrollto('#breadcrumbs');
    }
  }
}
export default App;

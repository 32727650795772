const baseUrl = "https://lppm.itn.ac.id/";
const baseWebminUrl = `${baseUrl}webmin/`;
const CONFIG = {
  KEY: "lppm-1tn",
  BASE_URL: baseUrl,
  BASE_WEBMIN_URL: baseWebminUrl,
  BASE_PHOTO_URL: `${baseWebminUrl}assets/uploads/foto/`,
  BASE_IMG_CONTENT_URL: `${baseUrl}assets/uploads/images/`,
  BASE_REPORT_URL: `${baseWebminUrl}assets/uploads/`,
};

export default CONFIG;

/* eslint-disable no-console */
import LppmDbSource from '../../data/lppmdb-source';
import {
  createListKegiatanTemplate,
  createHomeAboutTemplate,
  createHomeArticleTemplate,
  createHomePengumumanTemplate,
} from '../templates/template-creator';
import {
  hideHeroElm,
} from '../../utils/main';

const Beranda = {
  async render() {
    hideHeroElm(false);
    return `
    <!-- ======= Jml Kegiatan Section ======= -->
    <section id="j-kegiatan" class="clients section-bg">
      <div class="container">

        <div id="keg-list" class="row" data-aos="zoom-in"></div>

      </div>
    </section><!-- End Jml Kegiatan Section -->
    <div id="home"></div>
    <!-- ======= About Us Section ======= -->
    <section id="about-us" class="about">
      <div class="container" data-aos="fade-up">

        <div class="section-title">
          <h2>Profil LPPM ITN Malang</h2>
        </div>

        <div id="about-container" class="row content"></div>

      </div>
    </section><!-- End About Us Section -->

    <!-- ======= Pusat Riset & Inovasi Section ======= -->
    <section id="why-us" class="why-us section-bg">
      <div class="container-fluid aos-init aos-animate" data-aos="fade-up">

        <div class="row">

          <div class="col-lg-7 d-flex flex-column justify-content-center align-items-stretch  order-2 order-lg-1">

            <div class="content">
              <h3>Layanan Pusat <strong>Riset & Inovasi LPPM ITN Malang</strong></h3>
              <p>
                Kegiatan riset dan inovasi pada tingkat Institusi dikelola oleh Pusat Penelitian dan Inovasi Teknologi (Research and Technology Innovation Centre) yang pada implementasinya memfasilitasi kegiatan sebagai berikut:
              </p>
            </div>

            <div class="accordion-list">
              <ul>
                <li>
                  <a data-bs-toggle="collapse" data-bs-target="#accordion-list-1" class="collapsed"><span>01</span> Produk & Teknologi Tepat Guna <i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
                  <div id="accordion-list-1" class="collapse" data-bs-parent=".accordion-list">
                    <p>
                      Menghasilkan produk-produk yang merupakan implementasi ilmu pengetahuan dan teknologi serta menghasilkan produk teknologi tepat guna yang dapat digunakan secara langsung oleh masyarakat.
                    </p>
                    <p><a href="#/services/05">Lihat daftar produk...</a></p>
                    </div>
                    </li>
                    
                    <li>
                    <a data-bs-toggle="collapse" data-bs-target="#accordion-list-2" class="collapsed"><span>02</span> Pusat Pelatihan <i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
                    <div id="accordion-list-2" class="collapse" data-bs-parent=".accordion-list">
                    <p>
                      Memberikan program pelatihan (training) dan workshop untuk pengembangan sumber daya manusia di bidang energi baru terbarukan, tata ruang wilayah, sistem kendali Scada dan GIS.
                    </p>
                    <p><a href="#/services/06">Lihat daftar produk...</a></p>
                  </div>
                </li>

                <li>
                  <a data-bs-toggle="collapse" data-bs-target="#accordion-list-3" class="collapsed"><span>03</span> Jasa Konsultasi <i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
                  <div id="accordion-list-3" class="collapse" data-bs-parent=".accordion-list">
                    <p>
                      Memberikan jasa konsultasi dalam bidang perencanaan tata ruang wilayah, desain implementasi energi baru terbarukan, analisa lingkungan dan pengembangan teknologi pangan.
                    </p>
                    <p><a href="#/services/07">Lihat daftar produk...</a></p>
                  </div>
                </li>

                <li>
                  <a data-bs-toggle="collapse" data-bs-target="#accordion-list-4" class="collapsed"><span>04</span> Software Development <i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
                  <div id="accordion-list-4" class="collapse" data-bs-parent=".accordion-list">
                    <p>
                      Memberikan jasa Pengembangan Aplikasi atau Program Komputer, baik yang berbasis Web maupun Mobile (Android) untuk membantu mengelola Perusahaan atau Lembaga sesuai kebutuhan.
                    </p>
                    <p><a href="#/services/08">Lihat daftar produk...</a></p>
                  </div>
                </li>

              </ul>
            </div>

          </div>

          <div class="col-lg-5 align-items-stretch order-1 order-lg-2 img" style='background-image: url("assets/img/why-us.png");' data-aos="zoom-in" data-aos-delay="150">&nbsp;</div>
        </div>

      </div>
    </section><!-- End Pusat Riset & Inovasi Section -->

    <!-- ======= Pusat Solar Section ======= -->
    <section id="solar-power" class="about section-bg">
      <div class="container aos-init aos-animate" data-aos="fade-up">
        <div class="section-title">
          <h2>Solar Power Innovation Center</h2>
        </div>
        <div class="row content">
          <div class="col-lg-6 d-flex align-items-center" data-aos="fade-right" data-aos-delay="100">
            <img src="assets/img/solarpromotion.jpg" class="img-fluid" alt="Pelatihan Solar Power">
          </div>
          <div class="col-lg-6 pt-4 pt-lg-0" data-aos="fade-left" data-aos-delay="100">
            <p>Sebagai pusat pelatihan dan sertifikasi, Solar Power Innovation Center dapat menjadi tempat untuk melakukan training dan workshop bagi stake holder dari kalangan akademisi, mahasiswa, industri dan praktisi secara nasional. Dalam bidang Pemodelan dan Analisa Sistem Tenaga Listrik, Solar Power Innovation Center telah melakukan berbagi kegiatan training dan workshop.</p>
            <p>Adapun program-program pelatihan (training) dan workshop yang ditawarkan adalah sebagai berikut:</p>
            <ul>
              <li><i class="ri-check-double-line"></i> Power House dan Field Solar PV PLTS On-Grid</li>
              <li><i class="ri-check-double-line"></i> Power House dan Field Solar PV PLTS Off-Grid</li>
              <li><i class="ri-check-double-line"></i> Smart Home System berbasis Energi Surya</li>
              <li><i class="ri-check-double-line"></i> System SCADA monitoring PLTS On-Grid</li>
            </ul>
            <p><a href="#/solarpower/13" class="btn-read-more">Pelajari selengkapnya...</a></p>
          </div>
        </div>

      </div>
    </section>
    <!-- ======= End Pusat Solar Section ======= -->

    <!-- ======= Workshop Section ======= -->
    <section id="skills" class="skills">
      <div class="container" data-aos="fade-up">

        <div class="row">
          <div class="col-lg-6 d-flex align-items-center" data-aos="fade-right" data-aos-delay="100">
            <img src="assets/img/skills.png" class="img-fluid" alt="">
          </div>
          <div class="col-lg-6 pt-4 pt-lg-0 content" data-aos="fade-left" data-aos-delay="100">
            <h3>Pusat Pelatihan / Workshop</h3>
            <p class="fst-italic">
              Sebagai pusat pelatihan dan sertifikasi, Research and Inovation Centre ITN Malang dapat menjadi tempat untuk melakukan training dan workshop bagi stake holder dari kalangan akademisi, mahasiswa, industri dan praktisi secara nasional.
            </p>
            <div class="skills-content">

              <div class="progress">
                <a class="skill" href="#/workshop/09">Penulisan Artikel Ilmiah</a>
                <div class="detil-wrap">
                  Pelatihan penulisan artikel ilmiah seperti jurnal dan lain-lain
                </div>
              </div>

              <div class="progress">
                <a class="skill" href="#/workshop/10">Penyusunan Proposal Penelitian</a>
                <div class="detil-wrap">
                  Pelatihan penyusunan proposal penelitian hibah Eksternal
                </div>
              </div>

              <div class="progress">
                <a class="skill" href="#/workshop/11">Penyusunan Proposal Pengabdian</a>
                <div class="detil-wrap">
                  Pelatihan penyusunan proposal pengabdian kpd masyarakat hibah Eksternal
                </div>
              </div>

              <div class="progress">
                <a class="skill" href="#/workshop/12">Penyusunan Buku</a>
                <div class="detil-wrap">
                  Pelatihan penyusunan buku sebagai luaran penelitian
                </div>
              </div>

            </div>

          </div>
        </div>

      </div>
    </section><!-- End Skills Section -->

    <!-- ======= Cta Section ======= -->
    <section id="cta" class="cta">
      <div class="container" data-aos="zoom-in">

        <div class="row">
          <div class="col-lg-9 text-center text-lg-start">
            <h3>Daftar Pelatihan</h3>
            <p>Silahkan tekan tombol <strong>Registrasi</strong> untuk mendaftar pelatihan yang terdaftar pada <i>Layanan Kami</i>.</p>
          </div>
          <div class="col-lg-3 cta-btn-container text-center">
            <a class="cta-btn align-middle" href="#/registrasi-pelatihan">Registrasi</a>
          </div>
        </div>

      </div>
    </section><!-- End Cta Section -->

    <!-- ======= Pengumuman Section ======= -->
    <section id="pengumuman" class="faq section-bg">
      <div class="container" data-aos="fade-up">

        <div class="section-title">
          <h2>Pengumuman</h2>
          <p>Daftar Pengumuman terkait kegiatan Penelitian & Pengabdian Masyarakat serta Pelatihan di lingkungan LPPM ITN Malang</p>
        </div>

        <div id="pengumuman-container" class="faq-list content"></div>

      </div>
    </section><!-- End Pengumuman Section -->    

    <!-- ======= Berita Section ======= -->
    <section id="berita" class="services section-bg">
      <div class="container" data-aos="fade-up">

        <div class="section-title">
          <h2>Berita & Artikel Terbaru</h2>
          <p>Daftar berita dan artikel terbaru terkait kegiatan LITABMAS LPPM ITN Malang</p>
        </div>

        <div id="articles-container" class="row content"></div>

      </div>
    </section><!-- End Berita Section -->
    `;
  },

  async afterRender() {
    // Fungsi ini akan dipanggil setelah render()
    const data = await LppmDbSource.getFrontPage();
    const kegiatanContainer = document.querySelector('#keg-list');
    kegiatanContainer.innerHTML = createListKegiatanTemplate(data.litabmas, data.luaran);
    const aboutCountainer = document.querySelector('#about-container');
    aboutCountainer.innerHTML = createHomeAboutTemplate(data.profil.about_limited);
    const pengumumanContainer = document.querySelector('#pengumuman-container');
    pengumumanContainer.innerHTML = createHomePengumumanTemplate(data.pengumuman);
    const articlesContainer = document.querySelector('#articles-container');
    articlesContainer.innerHTML = createHomeArticleTemplate(data.artikel);
    //    console.log(data.profil.isi_1);
    //    console.log(data.pengumuman);
  },
};
export default Beranda;

import CONFIG from '../../globals/config';
import {
  decodeHtmlEntity,
} from '../../utils/main';

const writeDataKegiatanToElement = ({
  jmlPenelitian, jmlAbdimas, jmlJurnal, jmlForum, jmlHki, jmlLuaranLain,
}) => `
          <div class="col-lg-2 col-md-4 col-6 pb-0 d-flex align-items-center justify-content-center">
            <div class="col-12 bg-primary rounded p-2 m-1 text-white">
                Penelitian <span class="badge bg-secondary">${jmlPenelitian}</span>      
            </div>  
          </div>

          <div class="col-lg-2 col-md-4 col-6 pb-0 d-flex align-items-center justify-content-center">
            <div class="col-12 bg-success rounded p-2 m-1 text-white">
                Abdimas <span class="badge bg-light text-dark">${jmlAbdimas}</span>      
            </div>  
          </div>

          <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
            <div class="col-12 bg-secondary rounded p-2 m-1 text-white">
                Jurnal <span class="badge bg-danger">${jmlJurnal}</span>      
            </div>  
          </div>

          <div class="col-lg-2 col-md-4 col-6 pb-0 d-flex align-items-center justify-content-center">
            <div class="col-12 bg-warning rounded p-2 m-1 text-dark">
                Forum Ilmiah <span class="badge bg-dark">${jmlForum}</span>      
            </div>  
           </figure>  
          </div>

          <div class="col-lg-2 col-md-4 col-6 pb-0 d-flex align-items-center justify-content-center">
            <div class="col-12 bg-info rounded p-2 m-1 text-dark">
                HKI <span class="badge bg-success">${jmlHki}</span>      
            </div>  
          </div>

          <div class="col-lg-2 col-md-4 col-6 pb-0 d-flex align-items-center justify-content-center">
            <div class="col-12 bg-danger rounded p-2 m-1 text-white">
                Luaran Lain <span class="badge bg-warning text-dark">${jmlLuaranLain}</span>      
            </div>  
          </div>`;

const createListKegiatanTemplate = (litabmas, publikasi) => {
  let jmlPenelitian = 0;
  let jmlAbdimas = 0;
  let jmlJurnal = 0;
  let jmlForum = 0;
  let jmlHki = 0;
  let jmlLuaranLain = 0;

  litabmas.forEach((kegItem) => {
    if (kegItem.jk === 'R') jmlPenelitian = kegItem.jml;
    if (kegItem.jk === 'A') jmlAbdimas = kegItem.jml;
  });
  publikasi.forEach((pubItem) => {
    switch (pubItem.jns) {
      case '1':
        jmlJurnal = pubItem.jml;
        break;
      case '3':
        jmlForum = pubItem.jml;
        break;
      case '4':
        jmlHki = pubItem.jml;
        break;
      case '5':
        jmlLuaranLain = pubItem.jml;
        break;
      default:
        break;
    }
  });
  return writeDataKegiatanToElement({
    jmlPenelitian,
    jmlAbdimas,
    jmlJurnal,
    jmlForum,
    jmlHki,
    jmlLuaranLain,
  });
};

const createHomeAboutTemplate = (about) => `
    <div class="col-lg-12 pt-4 pt-lg-0" style="column-count: 2">
        ${decodeHtmlEntity(about)}
        <a href="#/about" class="btn-learn-more">Selengkapnya</a>
    </div>
`;

const createHomePriTemplate = (priList) => `
    ${priList}
`;

const createHomeArticleTemplate = (articleList) => {
  let articleElm = '';
  articleList.forEach((article) => {
    articleElm += `<div class="col-xl-4 col-md-6 pb-3 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
            <div class="icon-box w-100">
                <div class="date-created">${article.ts}</div>
              <h6><a href="">${article.judul_konten}</a></h6>
              ${decodeHtmlEntity(article.intro_konten)}<br>
                <a href="#/view/${article.id}/${article.kategori.id.substr(1, 2)}" class="btn-view-more">Selengkapnya</a>
            </div>
          </div>`;
  });
  return articleElm;
};

const createHomePengumumanTemplate = (articleList) => {
  let articleElm = '<ul>';
  let delay = 100;
  articleList.forEach((article) => {
    articleElm += `
            <li data-aos="fade-up" data-aos-delay="${delay}">
              <i class="bx bx-message-rounded-detail icon-help"></i> <a data-bs-toggle="collapse" class="collapse" data-bs-target="#faq-list-${delay}">${article.judul_konten} <i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
              <div id="faq-list-${delay}" class="collapse ${delay === 100 ? 'show' : ''}" data-bs-parent=".faq-list">
              ${decodeHtmlEntity(article.isi_konten)}
              </div>
            </li>`;
    delay += 100;
  });
  articleElm += '</ul>';
  return articleElm;
};

const createProfilTemplate = (profilList) => {
  let profilElm = '';
  profilList.forEach((profil) => {
    profilElm += `<div class="section-title">
          <h2>${profil.judul}</h2>
        </div>

        <div class="row content">${decodeHtmlEntity(profil.isi)}</div><br><br>`;
  });
  return profilElm;
};

const createProfilSkeletonTemplate = () => `
      <div class="section-title">
          <h2 class="skeleton-box">Lorem ipsum dolor sit.</h2>
        </div>

      <div class="row content">
        <p class="skeleton-box" style="height:300px">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus blanditiis sed nostrum, voluptatibus consequatur tenetur consectetur fugit quae neque quasi voluptate sit cumque similique natus molestiae laudantium nam! Illum, perspiciatis?
        </p>
      </div><br><br>`;

const createServicesTemplate = (serviceList) => {
  const [firstKey] = Object.keys(serviceList);
  document.querySelector('#services-title').innerHTML = serviceList[firstKey].kategori.nama;
  let serviceElm = '';
  serviceList.forEach((serviceItem) => {
    const photo = (serviceItem.photo === '' ? 'no-image.jpg' : serviceItem.photo);
    serviceElm += `
        <div class="col">
          <div class="card h-100">
            <img src="${CONFIG.BASE_IMG_CONTENT_URL}${photo}" class="card-img-top skeleton-img" alt="Service Image">
            <div class="card-body">
              <h5 class="card-title">${serviceItem.judul_konten}</h5>
              <div class="card-text">
                ${decodeHtmlEntity(serviceItem.intro_konten)}
              </div>
            </div>
            <div class="card-footer">
              <a href="#/view/${serviceItem.id}/${serviceItem.kategori.id.substr(1, 2)}" class="card-link w-100">Selengkapnya <i class="bx bx-right-arrow-circle float-end mt-2"></i></a>
            </div>
          </div>
        </div>`;
  });
  return serviceElm;
};
const createServiceSkeletonTemplate = (count) => {
  let template = '';

  for (let i = 0; i < count; i += 1) {
    template += `
        <div class="col">
          <div class="card">
            <img class="card-img-top skeleton-img" alt="">
            <div class="card-body">
              <h5 class="card-title skeleton-box">lorem ipsum dolor sit amet</h5>
              <p class="card-text skeleton-box">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. A adipisci alias aspernatur, assumenda aut consectetur.
              </p>
            </div>
            <div class="card-footer">
              <span class="skeleton-box">Selengkapnya</span>
            </div>
          </div>
        </div>`;
  }
  return template;
};

const createWorkshopTemplate = (workshopList) => {
  const [firstKey] = Object.keys(workshopList);
  document.querySelector('#workshop-title').innerHTML = workshopList[firstKey].kategori.nama;
  let workshopElm = '';
  workshopList.forEach((workshopItem) => {
    const photo = (workshopItem.photo === '' ? 'no-image.jpg' : workshopItem.photo);
    workshopElm += `
        <div class="col">
          <div class="card h-100">
            <img src="${CONFIG.BASE_IMG_CONTENT_URL}${photo}" class="card-img-top skeleton-img" alt="Workshop Image">
            <div class="card-body">
              <h5 class="card-title">${workshopItem.judul_konten}</h5>
              <div class="card-text">
                ${decodeHtmlEntity(workshopItem.intro_konten)}
              </div>
            </div>
            <div class="card-footer">
              <a href="#/view/${workshopItem.id}/${workshopItem.kategori.id.substr(1, 2)}" class="card-link w-100">Selengkapnya <i class="bx bx-right-arrow-circle float-end mt-2"></i></a>
            </div>
          </div>
        </div>`;
  });
  return workshopElm;
};
const createWorkshopSkeletonTemplate = (count) => {
  let template = '';

  for (let i = 0; i < count; i += 1) {
    template += `
        <div class="col">
          <div class="card">
            <img class="card-img-top skeleton-img" alt="">
            <div class="card-body">
              <h5 class="card-title skeleton-box">lorem ipsum dolor sit amet</h5>
              <p class="card-text skeleton-box">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. A adipisci alias aspernatur, assumenda aut consectetur.
              </p>
            </div>
            <div class="card-footer">
              <span class="skeleton-box">Selengkapnya</span>
            </div>
          </div>
        </div>`;
  }
  return template;
};

const createReadContentTemplate = (content) => {
  document.querySelector('#services-title').innerHTML = content.kategori.nama;
  const reglm = `<div class="col-12"><p>*** Silahkan melakukan pendaftaran <a href="#/registrasi-pelatihan/${content.id_event}">di link ini</a></p></div>`;
  return `
    <h4 class="services-item-title">${content.judul_konten}</h4>
        <div class="row content">
            <div class="col-12">
                ${decodeHtmlEntity(content.isi_konten)}
            </div>
            ${content.is_event ? reglm : ''}
        </div>`;
};

const createReadContentSkeletonTemplate = () => `
    <h4 class="workshops-item-title skeleton-box">Lorem ipsum dolor sit amet</h4>
      <div class="row content">
        <div class="col-12">
          <p class="skeleton-box" style="height:300px">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus blanditiis sed nostrum, voluptatibus consequatur tenetur consectetur fugit quae neque quasi voluptate sit cumque similique natus molestiae laudantium nam! Illum, perspiciatis?
          </p>
        </div>
      </div>`;
const generatePagination = (page, totalPage, url) => {
  let elmPager = '';
  const prevBtnStatus = (page === 1 ? ' disabled' : '');
  const nextBtnStatus = (page >= totalPage ? ' disabled' : '');
  elmPager = `
  <a href="${url}/${page - 1}" class="btn btn-default me-1${prevBtnStatus}">Sebelumnya</a>
  <a href="${url}/${page + 1}" class="btn btn-primary${nextBtnStatus}">Selanjutnya</a>
  `;
  return elmPager;
};
const createKegiatanSkeletonTemplate = (count) => {
  let template = '';

  for (let i = 0; i < count; i += 1) {
    template += `
      <div class="row g-2">
        <div class="col-6 mb-2">
          <p class="skeleton-box w-100 mb-1">
            Lorem ipsum dolor sit amet.
          </p>
          <span class="skeleton-box">Lorem ipsum dolor</span>
        </div>
        <div class="col-2 mb-2">
          <p class="skeleton-box w-100">
            Lorem ipsum
          </p>
        </div>
        <div class="col-3 mb-2">
          <p class="skeleton-box w-100">
            Lorem ipsum dolor.
          </p>
        </div>
        <div class="col-1 mb-2">
          <p class="skeleton-box w-100">
            Lorem
          </p>
        </div>
      </div>
  `;
  }
  return template;
};
const createRisetDetailTemplate = (jns, riset) => {
  let detil = `
      <div class="row border-bottom">
        <div class="col-4">
          <label class="fw-bold">Judul</label>
        </div>
        <div class="col-8">
          <label class="float-end fw-bold" title="Tahun & Skema">${riset.tahun} / ${riset.skim} <i class="bx bx-info-circle text-primary" title="Tahun & Skema"></i></label>
        </div>
        <div class="col-12"><p>${riset.judul}</p></div>
      </div>
      <div class="row border-bottom">
        <div class="col-6">
          <label class="fw-bold">Bidang Ilmu ${jns}</label>
        </div>
        <div class="col-6">
          <label class="fw-bold">Lokasi ${jns}</label>
        </div>
        <div class="col-6"><p>${riset.bidang_ilmu}</p></div>
        <div class="col-6"><p>${riset.lokasi}</p></div>
      </div>
      <div class="row border-bottom">
        <div class="col-12">
          <label class="fw-bold">Ketua ${(jns === 'Penelitian' ? 'Peneliti' : 'Pengabdi')}</label>
        </div>
        <div class="col-12"><p>${riset.creator.ketua[0].nama} / ${riset.creator.ketua[0].instansi}</p></div>
      </div>
      <div class="row border-bottom">
        <div class="col-12">
          <label class="fw-bold">Anggota</label>
        </div>
        <div class="col-12">
          <ol>`;
  riset.creator.anggota.forEach((tim) => {
    detil += `
              <li>${tim.nama} / ${tim.instansi}</li>
            `;
  });
  detil += `</ol>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <label class="fw-bold">Abstrak</label>
        </div>
        <div class="col-12"><p>${riset.abstrak}</p></div>
      </div>
      </div>
      `;
  return detil;
};

const createRisetDetailSkeletonTemplate = () => {
  const detil = `
      <div class="row">
        <div class="col-4 mb-1">
          <span class="skeleton-box">Judul</span>
        </div>
        <div class="col-8 mb-1">
          <span class="float-end skeleton-box">lorem ipsum</span>
        </div>
        <div class="col-12"><p class="skeleton-box w-100">Lorem ipsum dolor sit amet consectetur</p></div>
      </div>
      <div class="row">
        <div class="col-6 mb-1">
          <span class="skeleton-box">Bidang Ilmu Penelitian</span>
        </div>
        <div class="col-6 mb-1">
          <span class="skeleton-box">Lokasi Penelitian</span>
        </div>
        <div class="col-6"><p class="skeleton-box w-100">Lorem ipsum dolor sit amet consectetur</p></div>
        <div class="col-6"><p class="skeleton-box w-100">Lorem ipsum dolor sit amet consectetur</p></div>
      </div>
      <div class="row">
        <div class="col-12 mb-1">
          <span class="skeleton-box">Ketua Peneliti</span>
        </div>
        <div class="col-12"><p class="skeleton-box w-100">Lorem ipsum dolor sit amet consectetur</p></div>
      </div>
      <div class="row mb-3">
        <div class="col-12 mb-1">
          <span class="skeleton-box">Anggota</span>
        </div>
        <div class="col-12">
          <p class="skeleton-box w-100 mb-1">Lorem ipsum dolor sit amet consectetur</p>
          <p class="skeleton-box w-100 mb-1">Lorem ipsum dolor sit amet consectetur</p>
          <p class="skeleton-box w-100 mb-1">Lorem ipsum dolor sit amet consectetur</p>
        </div>
      </div>
      <div class="row">
        <div class="col-12 mb-1">
          <span class="skeleton-box">Abstrak</span>
        </div>
        <div class="col-12">
          <p class="skeleton-box w-100">
            Lorem ipsum dolor sit amet consectetur Lorem ipsum dolor sit amet consectetur Lorem ipsum dolor sit amet consectetur
          </p>
        </div>
      </div>
      </div>
      `;
  return detil;
};

const writeJmlPublikasiToElement = ({
  j,
  jmlJurnal, jmlBukuAjar, jmlForum, jmlHki, jmlLuaranLain,
}) => `<div class="row col-lg-12 gx-2 gy-2"> <!--row atas-->
    <div class="col-sm-4">
      <div class="card border-primary">
        <div class="card-header text-white bg-primary">
          <div class="row">
            <div class="col-sm-3"><i class="bx bx-globe display-1"></i></div>
            <div class="col-sm-9 text-end">
              <div class="w-100 h2 mb-0">${jmlJurnal}</div>
              <div class="w-100 h6 mt-0">JURNAL</div>
            </div>
          </div>
        </div>
        <div class="card-body py-2">
          <a href="./#/publikasi-jurnal/${j}" class="w-100 text-primary">Lihat Detail <i class="bx bx-right-arrow-circle float-end mt-1"></i></a>
        </div>
      </div>
    </div>
    <div class="col-sm-4">
      <div class="card border-success">
        <div class="card-header text-white bg-success">
          <div class="row">
            <div class="col-sm-3"><i class="bx bx-book display-1"></i></div>
            <div class="col-sm-9 text-end">
              <div class="w-100 h2 mb-0">${jmlBukuAjar}</div>
              <div class="w-100 h6 mt-0">BUKU AJAR/TEKS</div>
            </div>
          </div>
        </div>
        <div class="card-body py-2">
          <a href="./#/publikasi-buku/${j}" class="w-100 text-success">Lihat Detail <i class="bx bx-right-arrow-circle float-end mt-1"></i></a>
        </div>
      </div>
    </div>
    <div class="col-sm-4">
      <div class="card border-warning">
        <div class="card-header text-white bg-warning">
          <div class="row">
            <div class="col-sm-3"><i class="bx bx-group display-1"></i></div>
            <div class="col-sm-9 text-end">
              <div class="w-100 h2 mb-0">${jmlForum}</div>
              <div class="w-100 h6 mt-0">FORUM ILMIAH</div>
            </div>
          </div>
        </div>
        <div class="card-body py-2">
          <a href="./#/publikasi-forum/${j}" class="w-100 text-warning">Lihat Detail <i class="bx bx-right-arrow-circle float-end mt-1"></i></a>
        </div>
      </div>
    </div>
    <div class="col-sm-4">
      <div class="card border-danger">
        <div class="card-header text-white bg-danger">
          <div class="row">
            <div class="col-sm-3"><i class="bx bx-check-circle display-1"></i></div>
            <div class="col-sm-9 text-end">
              <div class="w-100 h2 mb-0">${jmlHki}</div>
              <div class="w-100 h6 mt-0">HAK KEKAYAAN INTELEKTUAL</div>
            </div>
          </div>
        </div>
        <div class="card-body py-2">
          <a href="./#/publikasi-hki/${j}" class="w-100 text-danger">Lihat Detail <i class="bx bx-right-arrow-circle float-end mt-1"></i></a>
        </div>
      </div>
    </div>
    <div class="col-sm-4">
      <div class="card border-secondary">
        <div class="card-header text-white bg-secondary">
          <div class="row">
            <div class="col-sm-3"><i class="bx bx-comment-dots display-1"></i></div>
            <div class="col-sm-9 text-end">
              <div class="w-100 h2 mb-0">${jmlLuaranLain}</div>
              <div class="w-100 h6 mt-0">LUARAN LAIN</div>
            </div>
          </div>
        </div>
        <div class="card-body py-2">
          <a href="./#/publikasi-lain/${j}" class="w-100 text-secondary">Lihat Detail <i class="bx bx-right-arrow-circle float-end mt-1"></i></a>
        </div>
      </div>
    </div>
  </div>`;

const createListJmlPublikasiTemplate = (j, data) => {
  let jmlJurnal = 0;
  let jmlBukuAjar = 0;
  let jmlForum = 0;
  let jmlHki = 0;
  let jmlLuaranLain = 0;
  data.forEach((item) => {
    switch (item.jns) {
      case '1':
        jmlJurnal = item.jml;
        break;
      case '2':
        jmlBukuAjar = item.jml;
        break;
      case '3':
        jmlForum = item.jml;
        break;
      case '4':
        jmlHki = item.jml;
        break;
      case '5':
        jmlLuaranLain = item.jml;
        break;
      default:
        break;
    }
  });
  return writeJmlPublikasiToElement({
    j, jmlJurnal, jmlBukuAjar, jmlForum, jmlHki, jmlLuaranLain,
  });
};

const createJmlPublikasiSkeletonTemplate = () => {
  let template = '<div class="row col-lg-12 gx-2 gy-2">';

  for (let i = 0; i < 5; i += 1) {
    template += `
    <div class="col-sm-4">
      <div class="card border-secondary">
        <div class="card-header skeleton">
          <div class="row">
            <div class="col-sm-3"><i class="bx bx-globe display-1 skeleton-box"></i></div>
            <div class="col-sm-9 text-end">
              <div class="w-100 h2 skeleton-box">12345</div>
              <div class="w-100 h6 skeleton-box">JURNAL</div>
            </div>
          </div>
        </div>
        <div class="card-body py-2">
          <p class="w-100 skeleton-box mb-0">lorem ipsum</p>
        </div>
      </div>
    </div>  
  `;
  }
  template += '</div>';
  return template;
};

const createDosenProdiTemplate = (prodiList) => {
  let prodiElm = '';
  const color = ['bg-primary', 'bg-success', 'bg-secondary'];
  let i = 0;
  prodiList.forEach((fakultasItem) => {
    let totalDosen = 0;
    prodiElm += `
        <div class="col">
          <div class="card">
            <div class="card-header ${color[i]} text-white">
              ${fakultasItem.fakultas.nama}
            </div>
            <div class="list-group list-group-flush">`;
    fakultasItem.fakultas.prodi.forEach((prodiItem) => {
      prodiElm += `
                <a href="#/profil-dosen/${prodiItem.id}/${fakultasItem.fakultas.id}" class="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
                  ${prodiItem.nama}
                  <span class="badge bg-primary badge-pill">${prodiItem.jml_dosen}</span>
                </a>
              `;
      totalDosen += parseInt(prodiItem.jml_dosen, 10);
    });
    prodiElm += `
            </div>
            <div class="card-footer text-muted d-flex justify-content-between">
              Total Dosen Fakultas <span class="badge bg-warning badge-pill">${totalDosen}</span>
            </div>
          </div>
        </div>`;
    i += 1;
  });
  return prodiElm;
};
const createDosenProdiSkeletonTemplate = (count) => {
  let template = '';

  for (let i = 0; i < count; i += 1) {
    template += `
        <div class="col">
          <div class="card">
            <div class="card-header skeleton-box">lorem ipsum dolor sit amet</div>
            <div class="card-body">
            <p class="skeleton-box my-1">lorem ipsum dolor sit amet</p>
            <p class="skeleton-box my-1">lorem ipsum dolor sit amet</p>
            <p class="skeleton-box my-1">lorem ipsum dolor sit amet</p>
            <p class="skeleton-box my-1">lorem ipsum dolor sit amet</p>
            <p class="skeleton-box my-1">lorem ipsum dolor sit amet</p>
            <p class="skeleton-box my-1">lorem ipsum dolor sit amet</p>
            <p class="skeleton-box my-1">lorem ipsum dolor sit amet</p>
            <p class="skeleton-box my-1">lorem ipsum dolor sit amet</p>
            </div>
            <div class="card-footer">
              <p class="skeleton-box mb-0">total dosen fakultas....</p>
            </div>
          </div>
        </div>`;
  }
  return template;
};

const createListDosenTemplate = (dosenList, fakId) => {
  let dosenElm = '';
  dosenList.dosen.forEach((dosen) => {
    const foto = (dosen.foto_profil === '' ? 'no_profile.gif' : dosen.foto_profil);
    let delay = 100;
    dosenElm += `
        <div class="col">
            <div class="member d-flex align-items-start h-100" data-aos="zoom-in" data-aos-delay="${delay}">
                <div class="pic"><img src="${CONFIG.BASE_PHOTO_URL}${foto}" class="profile-img" alt="foto profil"></div>
                <div class="member-info w-100">
                  <h4 class="w-100">${dosen.nama}</h4>
                  <span class="w-100">${dosen.jafung}</span>
                  <div class="my-1 w-100"><i class="ri-mail-line"></i> ${dosen.mail_addr}</div>
                  <div class="my-1 w-100"><i class="ri-user-2-line"></i> ${(dosen.struktural === '' ? '-' : dosen.struktural)}</div>
                  <div class="social">
                    <a href="#/riset/${dosen.id}/${fakId}" title="Penelitian"><i class="ri-flask-fill" title="Penelitian"></i></a>
                    <a href="#/abdimas/${dosen.id}/${fakId}" title="Abdimas"><i class="ri-hand-heart-fill" title="Abdimas"></i></a>
                    <a href="#/publikasi/${dosen.id}/${fakId}" title="Publikasi"><i class="ri-links-fill" title="Publikasi"></i></a>
                  </div>
              </div>              
            </div>
          </div>
        </div>`;
    delay += 100;
  });
  return dosenElm;
};

const createListDosenSkeletonTemplate = (count) => {
  let template = '';

  for (let i = 0; i < count; i += 1) {
    template += `
        <div class="col">
            <div class="member d-flex align-items-start">
              <div class="pic"><div class="skeleton-box" style="border-radius:50%;aspect-ratio:1/1;width:100%;height:auto;"></div></div>
              <div class="member-info">
                <h4 class="skeleton-box">Nama dosen</h4>
                <span class="skeleton-box">Jabatan / fungsional dosen</span>
                <p class="skeleton-box">Explicabo voluptatem mollitia et repellat qui dolorum quasi</p>
                <div class="social">
                  <a><i class="skeleton-box"></i></a>
                  <a><i class="skeleton-box"></i></a>
                  <a><i class="skeleton-box"></i></a>
                </div>
              </div>
            </div>
        </div>`;
  }
  return template;
};

const writeProfilDosen = (profil) => {
  if (profil.status) {
    const profilDosen = profil.data;
    const foto = (profilDosen.foto_profil === '' ? 'no_profile.gif' : profilDosen.foto_profil);
    const imgProfile = document.querySelector('.dosen-profile-img');
    imgProfile.src = `${CONFIG.BASE_PHOTO_URL}${foto}`;
    imgProfile.classList.remove('skeleton-img');
    const dosenName = document.querySelector('.dosen-name-text');
    dosenName.innerHTML = `<strong>${profilDosen.nama}</strong>`;
    dosenName.classList.remove('skeleton-box');
    const dosenInstansi = document.querySelector('.dosen-instansi-text');
    dosenInstansi.innerHTML = `${profilDosen.prodi.nama}${(profilDosen.struktural !== '' ? `<br>${profilDosen.struktural}` : '')}`;
    dosenInstansi.classList.add('text-muted');
    dosenInstansi.classList.remove('skeleton-box');
    document.querySelector('.badge-riset').innerHTML = profilDosen.statistik.kegiatan.riset;
    document.querySelector('.badge-abdimas').innerHTML = profilDosen.statistik.kegiatan.abdimas;
    document.querySelector('.badge-jurnal').innerHTML = profilDosen.statistik.publikasi.jurnal;
    document.querySelector('.badge-buku').innerHTML = profilDosen.statistik.publikasi.buku;
    document.querySelector('.badge-forum').innerHTML = profilDosen.statistik.publikasi.forum;
    document.querySelector('.badge-hki').innerHTML = profilDosen.statistik.publikasi.hki;
    document.querySelector('.badge-lain').innerHTML = profilDosen.statistik.publikasi.lain;
    document.querySelectorAll('.dosen-kp-text').forEach((el) => {
      el.classList.add('text-muted');
      el.classList.remove('skeleton-box');
    });
    document.querySelector('.sc').innerHTML = `<span>Scopus</span><span>: ${profilDosen.scopus_index}</span>`;
    document.querySelector('.gs').innerHTML = `<span>Google Scholar</span><span>: ${profilDosen.google_scholar_index}</span>`;
    const dosenMail = document.querySelector('.dosen-mail-text');
    dosenMail.innerHTML = profilDosen.mail_addr;
    dosenMail.classList.add('text-muted');
    dosenMail.classList.remove('skeleton-box');
  }
};

const createRegWorkshopFormTemplate = `
<div class="d-flex justify-content-center">
  <div class="col-lg-8 col-md-10 col-sm-12 mt-3 mt-lg-0">
    <form id="form-reg" class="reg-ws-form">
      <input type="hidden" name="ip" id="ip">
      <div class="form-group">
        <label for="name">Pelatihan yang dibuka</label>
        <select name="events" class="form-select" id="events" onchange="this.setCustomValidity('')" required>
          <option value="0">Pilih Pelatihan</option>
        </select>
      </div>
        <div class="row">
          <div class="form-group col-lg-6 col-md-6 col-sm-12">
          <label for="name">NIDN</label>
          <input type="text" class="form-control" name="nidn" id="nidn" placeholder="Ketikkan NIDN Anda" oninvalid="this.setCustomValidity('Kolom NIDN harus diisi')"
    onchange="this.setCustomValidity('')" max="20" required>
          </div>
          <div class="form-group col-lg-6 col-md-6 col-sm-12">
            <label for="name">Nama Lengkap & gelar</label>
            <input type="text" name="name" class="form-control" id="name" placeholder="ketikkan nama lengkap & gelar Anda" oninvalid="this.setCustomValidity('Kolom nama lengkap harus diisi')"
    onchange="this.setCustomValidity('')" max="200" required>
          </div>
        </div>
        <div class="form-group">
          <label for="name">Asal Institusi</label>
          <input type="text" class="form-control" max="255" name="institusi" id="institusi" placeholder="Ketikkan nama Institusi Anda" oninvalid="this.setCustomValidity('Kolom asal institusi harus diisi')"
    onchange="this.setCustomValidity('')" required>
        </div>
        <div class="row">
          <div class="form-group col-lg-6 col-md-6 col-sm-12">
            <label for="name">Nomor Handphone</label>
            <input type="number" class="form-control" name="hp" id="hp" placeholder="Misal: 081123411112" oninvalid="this.setCustomValidity('Kolom nomor handphone harus diisi')"
    onchange="this.setCustomValidity('')" required>
          </div>
          <div class="form-group col-lg-6 col-md-6 col-sm-12">
            <label for="name">Alamat e-Mail</label>
            <input type="email" class="form-control" name="email" id="email" placeholder="misal: abcde@institusi.ac.id" oninvalid="this.setCustomValidity('Ketikkan alamat email yang valid')"
    onchange="this.setCustomValidity('')" required>
          </div>
        </div>
        <div class="text-center"><button id="sendButton" class="btn btn-outline-primary" aria-label="Daftarkan Saya">Daftarkan Saya</button></div>
      </form>
    </div>
  </div>
`;

const createRegWorkshopTemplate = `
        ${createRegWorkshopFormTemplate}
        `;

const createErrorPageTemplate = (code) => {
  let errElm = '';
  if (code === 404) {
    errElm = `
        <div id="container" class="container align-content-center" data-aos="fade-up">
            <center>
                <h2>404: Halaman yang Anda tuju tidak ditemukan</h2>
                <img src="/assets/img/404.jpg" class="img-fluid mx-auto" alt="404 Image by storyset on Freepik">
            </center>
        </div>
        `;
  } else if (code === 204) {
    errElm = `
        <div id="container" class="container align-content-center" data-aos="fade-up">
            <center>
                <h2>204: Data tidak ditemukan</h2>
                <img src="/assets/img/204.png" class="img-fluid mx-auto" alt="No Content">
            </center>
        </div>
        `;
  } else {
    errElm = `
        <div id="container" class="container align-content-center" data-aos="fade-up">
            <center>
                <h2>204: Data tidak ditemukan</h2>
                <img src="/assets/img/404.jpg" class="img-fluid mx-auto" alt="Image by storyset on Freepik">
            </center>
        </div>
        `;
  }
  return errElm;
};

export {
  createListKegiatanTemplate,
  createHomeAboutTemplate,
  createHomePriTemplate,
  createHomeArticleTemplate,
  createHomePengumumanTemplate,
  createProfilTemplate,
  createProfilSkeletonTemplate,
  createServicesTemplate,
  generatePagination,
  createServiceSkeletonTemplate,
  createWorkshopTemplate,
  createWorkshopSkeletonTemplate,
  createErrorPageTemplate,
  createRisetDetailTemplate,
  createRisetDetailSkeletonTemplate,
  createKegiatanSkeletonTemplate,
  createListJmlPublikasiTemplate,
  createJmlPublikasiSkeletonTemplate,
  createReadContentTemplate,
  createReadContentSkeletonTemplate,
  createDosenProdiTemplate,
  createDosenProdiSkeletonTemplate,
  createListDosenSkeletonTemplate,
  createListDosenTemplate,
  writeProfilDosen,
  createRegWorkshopTemplate,
};

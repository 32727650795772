import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';

const $ = require('jquery');

const BORDER_COLORS = [
  'rgba(255, 76, 48, 1)', // red:
  'rgba(255, 159, 64, 1)', // orange:
  'rgba(138, 214, 153, 1)', // grey:
  'rgba(75, 192, 192, 1)', // green:
  'rgba(255, 205, 86, 1)', // yellow:
  'rgba(54, 162, 235, 1)', // blue
  'rgba(153, 102, 255, 1)', // purple:
];

const BG_COLORS = [
  'rgba(255, 76, 48, 0.3)', // red:
  'rgba(255, 159, 64, 0.3)', // orange:
  'rgba(138, 214, 153, 0.3)', // green:
  'rgba(75, 192, 192, 0.3)', // tosca:
  'rgba(255, 205, 86, 0.3)', // yellow:
  'rgba(54, 162, 235, 0.3)', // blue
  'rgba(153, 102, 255, 0.3)', // purple:
];

Chart.register(ChartDataLabels);
export default function createChart(data, chartElm, chartLabel, colorIndex) {
  const areaChartCanvas = $(chartElm).get(0).getContext('2d');
  const kegLabels = [];
  data.forEach((item) => kegLabels.push(item.tahun));
  const kegData = [];
  data.forEach((item) => kegData.push(item.jml));
  const areaChartData = {
    labels: kegLabels,
    datasets: [
      {
        label: chartLabel,
        backgroundColor: BG_COLORS[colorIndex],
        borderColor: BORDER_COLORS[colorIndex], // 'rgba(138,214,153,1)',
        data: kegData,
        pointStyle: 'circle',
        pointRadius: 3,
        pointHoverRadius: 6,
        tension: 0.4,
        fill: 'start',
      },
    ],
  };

  const areaChartOptions = {
    maintainAspectRatio: false,
    aspectRatio: 5 / 3,
    layout: {
      padding: {
        top: 30,
        right: 16,
        bottom: 8,
        left: 8,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        stacked: true,
      },
      /*       x: {
        type: 'linear',
      },
 */ },
    responsive: true,
    plugins: {
      title: {
        display: false,
        text: `Jumlah ${chartLabel} terbaru`,
      },
      legend: {
        display: false,
      },
      datalabels: {
        backgroundColor(context) {
          return context.dataset.borderColor;
        },
        borderRadius: 10,
        color: 'white',
        font: {
          size: '10px',
        //   weight: 'bold',
        },
        formatter: Math.round,
        padding: 4,
        anchor: 'end',
        align: 'top',
      },
    },
  };

  document.querySelector(`${chartElm}-box`).classList.remove('skeleton-box');
  document.querySelector(`${chartElm}-title`).innerHTML = `Jumlah ${chartLabel} terbaru`;
  // This will get the first returned node in the jQuery collection.
  // eslint-disable-next-line no-new
  new Chart(areaChartCanvas, {
    type: 'line',
    data: areaChartData,
    options: areaChartOptions,
  });
}

import CONFIG from "./config";

const API_ENDPOINT = {
  FRONTPAGE: `${CONFIG.BASE_WEBMIN_URL}api/content`,
  PROFIL: `${CONFIG.BASE_WEBMIN_URL}api/content/profil`,
  PRI: (id, p) =>
    `${CONFIG.BASE_WEBMIN_URL}api/content/pri?kategori=C${id}&p=${p}`,
  RSG: (id, p) =>
    `${CONFIG.BASE_WEBMIN_URL}api/content/rsg?kategori=C${id}&p=${p}`,
  WS: (id, p) =>
    `${CONFIG.BASE_WEBMIN_URL}api/content/ws?kategori=C${id}&p=${p}`,
  READ_CONTENT: (id) => `${CONFIG.BASE_WEBMIN_URL}api/content/view?id=${id}`, // tampilkan konten
  RISET: (jns, sd) =>
    `${CONFIG.BASE_WEBMIN_URL}api/kegiatan?jns=${jns}&sd=${sd}`, // get list penelitian
  KEGIATAN_DOSEN: (jns, dosen) =>
    `${CONFIG.BASE_WEBMIN_URL}api/kegiatan/per_dosen?jns=${jns}&dosen=${dosen}`, // get list kegiatan dosen
  JML_PUBLIKASI: (jns) =>
    `${CONFIG.BASE_WEBMIN_URL}api/publikasi/count?jns=${jns}`, // get jumlah publikasi
  JURNAL: (jns) => `${CONFIG.BASE_WEBMIN_URL}api/publikasi/jurnal?jns=${jns}`, // get list jurnal
  JURNAL_DOSEN: (dosen) =>
    `${CONFIG.BASE_WEBMIN_URL}api/publikasi/jurnal_dosen?id=${dosen}`, // get list jurnal dosen
  BUKU_AJAR: (jns) => `${CONFIG.BASE_WEBMIN_URL}api/publikasi/buku?jns=${jns}`, // get list buku
  BUKU_DOSEN: (dosen) =>
    `${CONFIG.BASE_WEBMIN_URL}api/publikasi/buku_dosen?id=${dosen}`, // get list buku dosen
  FORUM_ILMIAH: (jns) =>
    `${CONFIG.BASE_WEBMIN_URL}api/publikasi/forum_ilmiah?jns=${jns}`, // get list forum
  FORUM_ILMIAH_DOSEN: (dosen) =>
    `${CONFIG.BASE_WEBMIN_URL}api/publikasi/forum_ilmiah_dosen?id=${dosen}`, // get list forum dosen
  HKI: (jns) => `${CONFIG.BASE_WEBMIN_URL}api/publikasi/hki?jns=${jns}`, // get list hki
  HKI_DOSEN: (dosen) =>
    `${CONFIG.BASE_WEBMIN_URL}api/publikasi/hki_dosen?id=${dosen}`, // get list HKI dosen
  LUARAN_LAIN: (jns) =>
    `${CONFIG.BASE_WEBMIN_URL}api/publikasi/luaran_lain?jns=${jns}`, // get list luaran lainnya
  LUARAN_LAIN_DOSEN: (dosen) =>
    `${CONFIG.BASE_WEBMIN_URL}api/publikasi/luaran_lain_dosen?id=${dosen}`, // get list luaran lain dosen
  JML_DOSEN_PRODI: `${CONFIG.BASE_WEBMIN_URL}api/dosen/count`, // get jumlah dosen PRODI
  DOSEN_PRODI: (prodi) => `${CONFIG.BASE_WEBMIN_URL}api/dosen?prodi=${prodi}`, // get dosen prodi
  PROFIL_DOSEN: (id) => `${CONFIG.BASE_WEBMIN_URL}api/dosen/profil?id=${id}`, // get profil dosen prodi
  SCOPUS_DOSEN: (id) =>
    `http://api.elsevier.com/content/author?author_id=${id}&apiKey=e3f01682240f762f332ee6d04a315263}`, // get profil scopus dosen
  EVENTS_OPEN: `${CONFIG.BASE_WEBMIN_URL}api/event/list_open`,
  EVENTS_REG: `${CONFIG.BASE_WEBMIN_URL}api/event/reg`,
  IP_ADDR: "https://api.ipify.org/?format=json",
};

export default API_ENDPOINT;
